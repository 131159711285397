const prod = {
    url: {
        API_URL: 'https://conquest-backend.onrender.com/api',
        SERVER_URL: 'https://conquest-backend.onrender.com',
        APP_URL: 'https://conquest.money'
    },
    DAY_MONTH_FORMAT: 'D MMM.',
    DATE_FORMAT: 'D MMM YYYY',
    TIME_FORMAT: 'h:mma',
    DATE_TIME_FORMAT: 'D MMM YYYY | h:mma',
    production: true
};

const dev = {
    url: {
        API_URL: 'http://127.0.0.1:8000/api',
        SERVER_URL: "http://localhost:8000/",
        APP_URL: 'http://localhost:3000'
    },
    DAY_MONTH_FORMAT: 'D MMM.',
    DATE_FORMAT: 'D MMM YYYY',
    TIME_FORMAT: 'h:mma',
    DATE_TIME_FORMAT: 'D MMM YYYY | h:mma',
    production: false
};

export const config = process.env.NODE_ENV === 'development' ? dev : prod;
