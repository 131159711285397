import ApiService from "./ApiService";
import AuthService from "./AuthService";

export default {
    getCategories(success, failure) {
        ApiService.get('/get_categories', AuthService.getHeaders()).then(success).catch(failure);
    },
    getCategoryRules(success, failure) {
        ApiService.get('/get_category_rules', AuthService.getHeaders()).then(success).catch(failure);
    },
    createCategory(data, success, failure) {
        ApiService.post('/create_category', data, AuthService.getHeaders()).then(success).catch(failure);
    },
    bulkCreateCategories(categories, success, failure) {
        ApiService.post('/bulk_create_categories', categories, AuthService.getHeaders())
                  .then(success)
                  .catch(failure);
    },
    updateCategory(data, success, failure) {
        ApiService.put('/update_category', data, AuthService.getHeaders()).then(success).catch(failure);
    },
    deleteCategory(id, success, failure) {
        ApiService.post(`/delete_category`, {
            'category_id': id,
        }, AuthService.getHeaders()).then(success).catch(failure);
    },
    createCategoryRule(data, success, failure) {
        ApiService.post('/create_category_rule', data, AuthService.getHeaders()).then(success).catch(failure);
    },
    updateCategoryRule(data, success, failure) {
        ApiService.put('/update_category_rule', data, AuthService.getHeaders()).then(success).catch(failure);
    },
    deleteCategoryRule(id, success, failure) {
        ApiService.post(`/delete_category_rule`, {
            'category_rule_id': id,
        }, AuthService.getHeaders()).then(success).catch(failure);
    }
}