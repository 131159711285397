import {useEffect, useState} from 'react';
import {Outlet, useNavigate} from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import auth from "../../utils/auth";
import UserService from "../../services/UserService";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (!user) {
      UserService.getCurrentUser(response => {
        setUser(response.data);
      }, error => {
        if (error.response.status === 401) {
          // todo should eventually do this for all 401 requests probably
          auth.removeAuthToken();
          navigate('/login', { replace: true });
          console.log("Unauthenticated trying to get user, returning to login")
        } else {
          console.log(error);
        }
      })
    }
  }, [open]);

  return (
    <RootStyle>
      <DashboardNavbar user={user} onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar user={user} isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <MainStyle>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
