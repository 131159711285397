import ApiService from "./ApiService";
import AuthService from "./AuthService";

export default {
    getBudgets(tzOffset, success, failure) {
        ApiService.get(`/budgets?tzOffset=${tzOffset}`, AuthService.getHeaders()).then(success).catch(failure);
    },
    createBudget(data, success, failure) {
        ApiService.post('/budgets/', data, AuthService.getHeaders()).then(success).catch(failure);
    },
    updateBudget(id, data, success, failure) {
        ApiService.put(`/budgets/${id}/`, data, AuthService.getHeaders()).then(success).catch(failure);
    },
    deleteBudget(id, success, failure) {
        ApiService.delete(`/delete_budget/${id}/`, AuthService.getHeaders()).then(success).catch(failure);
    },
}
