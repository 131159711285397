import {useEffect, useState} from 'react';
// material
import {
    DialogTitle,
    DialogContentText,
    DialogContent,
    DialogActions,
    Button,
    Dialog,
    TextField,
    FormGroup,
    FormControlLabel,
    Switch, InputAdornment, Autocomplete, Divider, Checkbox
} from '@mui/material';
// component
import {useForm} from "react-hook-form";
import CategoryService from "../../../services/CategoryService";
import ConfirmationDialog from "../../../components/ConfirmationDialog";

// ----------------------------------------------------------------------

export default function CategoryRuleModal({isOpen, handleClose, transaction, rule, categories}) {
    const [betweenTwoDatesRuleEnabled, setBetweenTwoDatesRuleEnabled] = useState(false);
    const [startDay, setStartDay] = useState();
    const [endDay, setEndDay] = useState();

    const [merchantKeywordsRuleEnabled, setMerchantKeywordsRuleEnabled] = useState(false);
    const [merchantKeywords, setMerchantKeywords] = useState("");

    const [transactionKeywordsRuleEnabled, setTransactionKeywordsRuleEnabled] = useState(false);
    const [transactionKeywords, setTransactionKeywords] = useState("");

    const [betweenTwoAmountsRuleEnabled, setBetweenTwoAmountsRuleEnabled] = useState(false);
    const [minAmount, setMinAmount] = useState();
    const [maxAmount, setMaxAmount] = useState();

    const [category, setCategory] = useState();

    const [applyToExistingTransactions, setApplyToExistingTransactions] = useState(false);

    useEffect(() => {
        if (rule) {
            if (rule.start_day_of_month && rule.end_day_of_month) {
                setStartDay(rule.start_day_of_month);
                setEndDay(rule.end_day_of_month);
                setBetweenTwoDatesRuleEnabled(true);
            }

            if (rule.keywords_in_transaction_name && rule.keywords_in_transaction_name.length) {
                setTransactionKeywords(rule.keywords_in_transaction_name.join(', '));
                setTransactionKeywordsRuleEnabled(true);
            }

            if (rule.keywords_in_merchant_name && rule.keywords_in_merchant_name.length) {
                setMerchantKeywords(rule.keywords_in_merchant_name.join(', '));
                setMerchantKeywordsRuleEnabled(true);
            }

            if (rule.min_amount && rule.max_amount) {
                setMinAmount(rule.min_amount);
                setMaxAmount(rule.max_amount);
                setBetweenTwoAmountsRuleEnabled(true);
            }

            handleCategoryChange(categories.find(c => c.id === rule.category))
        } else if (transaction) {
            let txnDay = transaction.transaction_date.getDate();
            setStartDay(txnDay);
            setEndDay(txnDay);
            setBetweenTwoDatesRuleEnabled(true);
            if (transaction.plaid_merchant_name) {
                setMerchantKeywords(transaction.plaid_merchant_name.replace(/[ ,]+/g, ", "));
                setMerchantKeywordsRuleEnabled(true);
            }
            if (transaction.transaction_name) {
                setTransactionKeywords(transaction.transaction_name.replace(/[ ,]+/g, ", "));
                setTransactionKeywordsRuleEnabled(true);
            }
            setMinAmount(transaction.amount);
            setMaxAmount(transaction.amount);
            setBetweenTwoAmountsRuleEnabled(true);
        } else {
            setStartDay(null);
            setEndDay(null);
            setBetweenTwoDatesRuleEnabled(false);
            setMerchantKeywords("");
            setMerchantKeywordsRuleEnabled(false);
            setTransactionKeywords("");
            setTransactionKeywordsRuleEnabled(false);
            setMinAmount(null);
            setMaxAmount(null);
            setBetweenTwoAmountsRuleEnabled(false);
        }
    }, [isOpen]);

    const handleBetweenTwoDatesRuleChange = () => {
        setBetweenTwoDatesRuleEnabled((previousBetweenTwoDatesRuleEnabled) => !previousBetweenTwoDatesRuleEnabled);
    }

    const handleMerchantKeywordsRuleEnabledChange = () => {
        setMerchantKeywordsRuleEnabled((previousMerchantKeywordsRuleEnabled) => !previousMerchantKeywordsRuleEnabled);
    }

    const handleTransactionKeywordsRuleEnabledChange = () => {
        setTransactionKeywordsRuleEnabled((previousTransactionKeywordsRuleEnabled) => !previousTransactionKeywordsRuleEnabled);
    }

    const handleBetweenTwoAmountsRuleChange = () => {
        setBetweenTwoAmountsRuleEnabled(previousBetweenTwoAmountsRuleEnabled => !previousBetweenTwoAmountsRuleEnabled);

    }

    const handleApplyToExistingTransactions = () => {
        setApplyToExistingTransactions(!applyToExistingTransactions);
    }

    useEffect(() => {
        if (!betweenTwoDatesRuleEnabled) {
            setStartDay(null);
            setEndDay(null);
        }
        if (!merchantKeywordsRuleEnabled) {
            setMerchantKeywords("");
        }
        if (!transactionKeywordsRuleEnabled) {
            setTransactionKeywords("");
        }
        if (!betweenTwoAmountsRuleEnabled) {
            setMinAmount(null);
            setMaxAmount(null);
        }
    }, [betweenTwoDatesRuleEnabled, merchantKeywordsRuleEnabled, transactionKeywordsRuleEnabled, betweenTwoAmountsRuleEnabled]);

    const handleStartDayChange = (event) => setStartDay(event.target.value);
    const handleEndDayChange = (event) => setEndDay(event.target.value);
    const handleMerchantKeywordsChange = (event) => setMerchantKeywords(event.target.value);
    const handleTransactionKeywordsChange = (event) => setTransactionKeywords(event.target.value);
    const handleMinAmountChange = (event) => setMinAmount(event.target.value);
    const handleMaxAmountChange = (event) => setMaxAmount(event.target.value);

    const handleCategoryChange = (newCategory) => {
        setCategory(newCategory);
    }

    const handleUpdate = () => {
        // todo add validation and "*required"
        let merchantKeywordsList = [];
        if (merchantKeywords) {
            merchantKeywordsList = merchantKeywords.split(",").map(item => item.trim())
        }

        let transactionKeywordsList = [];
        if (transactionKeywords) {
            transactionKeywordsList = transactionKeywords.split(",").map(item => item.trim())
        }

        CategoryService.updateCategoryRule({
            category_rule_id: rule.id,
            start_day_of_month: startDay,
            end_day_of_month: endDay,
            keywords_in_merchant_name: merchantKeywordsList,
            keywords_in_transaction_name: transactionKeywordsList,
            min_amount: minAmount,
            max_amount: maxAmount,
        }, response => {
            handleClose(true);
        }, error => {
            console.log(error);
        });
    }

    const handleCreate = () => {
        // todo add validation and "*required"
        let merchantKeywordsList = [];
        if (merchantKeywords) {
            merchantKeywordsList = merchantKeywords.split(",").map(item => item.trim())
        }

        let transactionKeywordsList = [];
        if (transactionKeywords) {
            transactionKeywordsList = transactionKeywords.split(",").map(item => item.trim())
        }

        CategoryService.createCategoryRule({
            start_day_of_month: startDay,
            end_day_of_month: endDay,
            keywords_in_merchant_name: merchantKeywordsList,
            keywords_in_transaction_name: transactionKeywordsList,
            min_amount: minAmount,
            max_amount: maxAmount,
            category_id: category.id,
            apply_to_existing_uncategorized_transactions: applyToExistingTransactions
        }, response => {
            handleClose(true);
        }, error => {
            console.log(error);
        });
    }

    return (
        <>
            <Dialog
                open={isOpen}
                onClose={handleClose}>

                { rule ?
                    <DialogTitle>Edit category rule</DialogTitle>
                    :
                    <DialogTitle>New category rule</DialogTitle>
                }

                <DialogContent>

                    { rule ?
                    <DialogContentText>
                        To assign a new category altogether, please delete this rule and create another one.
                    </DialogContentText> :
                    <DialogContentText>
                        Setup a new category rule. Once created, any new transactions that match the criteria will be automatically categorised.
                    </DialogContentText>}

                    <br/>

                    <FormGroup>
                        <FormControlLabel control={<Switch checked={betweenTwoDatesRuleEnabled}
                                                           onChange={handleBetweenTwoDatesRuleChange}/>}
                                          label="If the day of transaction is between these days"/>
                    </FormGroup>

                    {
                        betweenTwoDatesRuleEnabled &&
                        <>
                            <DialogContentText>

                            </DialogContentText>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="start_day_of_month"
                                label="Start day (e.g. 1)"
                                type="number"
                                variant="standard"
                                value={startDay}
                                onChange={handleStartDayChange}
                            />
                            &nbsp;
                            <TextField
                                autoFocus
                                margin="dense"
                                id="end_day_of_month"
                                label="End day (e.g. 31)"
                                type="number"
                                variant="standard"
                                value={endDay}
                                onChange={handleEndDayChange}
                            />
                            <br/>
                        </>
                    }

                    <br/>
                    <b>AND</b>
                    <br/>
                    <br/>

                    <FormGroup>
                        <FormControlLabel control={<Switch checked={merchantKeywordsRuleEnabled}
                                                           onChange={handleMerchantKeywordsRuleEnabledChange}/>}
                                          label="If these keywords are in the merchant name"/>
                    </FormGroup>

                    {
                        merchantKeywordsRuleEnabled &&
                        <>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="merchant_keywords"
                                label="Keywords (e.g. ubereats, doordash, skipthedishes)"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={merchantKeywords}
                                onChange={handleMerchantKeywordsChange}
                            />

                            <br/>
                        </>
                    }

                    <br/>
                    <b>AND</b>
                    <br/>
                    <br/>

                    <FormGroup>
                        <FormControlLabel control={<Switch checked={transactionKeywordsRuleEnabled}
                                                           onChange={handleTransactionKeywordsRuleEnabledChange}/>}
                                          label="If these keywords are in the transaction name"/>
                    </FormGroup>

                    {transactionKeywordsRuleEnabled &&
                        <>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="transaction_keywords"
                                label="Keywords (e.g. sport, ski, skate)"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={transactionKeywords}
                                onChange={handleTransactionKeywordsChange}
                            />

                            <br/>
                        </>
                    }

                    <br/>
                    <b>AND</b>
                    <br/>
                    <br/>

                    <FormGroup>
                        <FormControlLabel control={<Switch checked={betweenTwoAmountsRuleEnabled}
                                                           onChange={handleBetweenTwoAmountsRuleChange}/>}
                                          label="If the transaction amount is between"/>
                    </FormGroup>

                    {betweenTwoAmountsRuleEnabled &&
                        <>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="min_amount"
                                label="Min (e.g. $10)"
                                type="number"
                                variant="standard"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                                value={minAmount}
                                onChange={handleMinAmountChange}
                            />
                            &nbsp;
                            <TextField
                                autoFocus
                                margin="dense"
                                id="max_amount"
                                label="and max (e.g. $20)"
                                type="number"
                                variant="standard"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                                value={maxAmount}
                                onChange={handleMaxAmountChange}
                            />

                            <br/>
                        </>
                    }

                    <br/>
                    <b>THEN</b>
                    <br/>
                    <br/>

                    <DialogContentText>
                        Automatically assign this category,
                    </DialogContentText>
                    <br/>
                    { rule ?
                        <TextField fullWidth
                                   disabled
                                   value={category ? category.display_name : ""}
                                   variant="standard"/>
                        :
                        <Autocomplete
                            disabled={rule != null}
                            disablePortal
                            selectOnFocus
                            handleHomeEndKeys
                            autoHighlight
                            id="category-rule-category"
                            options={categories}
                            getOptionLabel={(option) => option.display_name}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            value={category}
                            onChange={(event, newCategory) => {
                                handleCategoryChange(newCategory);
                            }}
                            renderInput={(params) => {
                                return <TextField {...params}
                                                  variant="standard"/>;
                            }}
                        />}

                    {/* Don't allow applying to matching transactions when updating. */}
                    {
                        !rule && <FormGroup>
                            <FormControlLabel control={<Checkbox checked={applyToExistingTransactions}
                                                                 onChange={handleApplyToExistingTransactions}/>}
                                              label="Apply to all matching uncategorized transactions"/>
                        </FormGroup>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    { rule ? <Button onClick={handleUpdate}>Update</Button> :
                        <Button onClick={handleCreate}>Create</Button>}
                </DialogActions>
            </Dialog>

        </>
    );
}
