import {faker} from '@faker-js/faker';
// @mui
import {useTheme} from '@mui/material/styles';
import {Grid, Container, Typography, TextField, Divider} from '@mui/material';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
// sections
import {
    AppTasks,
    AppNewsUpdate,
    AppOrderTimeline,
    AppCurrentVisits,
    FinancialSnapshotMonthly,
    AppTrafficBySite,
    AppWidgetSummary,
    AppCurrentSubject,
    AppConversionRates,
} from '../sections/@dashboard/app';
import {fCurrency} from "../utils/formatNumber";
import {useEffect, useState} from "react";
import DashboardService from "../services/DashboardService";
import SpendingByCategory from "../sections/@dashboard/app/SpendingByCategory";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import DashboardWidgetSummary from "../sections/@dashboard/app/DashboardWidgetSummary";
import SankeyChart from "../sections/@dashboard/app/SankeyChart";

// ----------------------------------------------------------------------

export default function DashboardApp() {
    const theme = useTheme();

    const [monthYear, setMonthYear] = useState(dayjs());

    const SHOW_EXAMPLES = process.env.NODE_ENV === 'development';

    const [dashboard, setDashboard] = useState({});

    const getDashboard = () => {
        DashboardService.getDashboard(monthYear.$M + 1, monthYear.$y, response => {
            setDashboard(response.data.dashboard);
        }, error => {
            console.log(error);
        })
    }

    useEffect(() => {
        getDashboard();
    }, [monthYear])

    return (
        <Page title="Dashboard">
            <Container maxWidth="xl">
                <Typography variant="h4" sx={{mb: 2}}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        name="monthYear"
                        views={['year', 'month']}
                        minDate={dayjs('1900-01-01')}
                        value={monthYear}
                        onChange={(newDate) => setMonthYear(newDate)}
                        renderInput={(params) => <TextField name="monthYear" {...params} helperText={null}/>}
                    />
                </LocalizationProvider>
                </Typography>

                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={3}>
                        <DashboardWidgetSummary title="Net Worth" total={fCurrency(dashboard.net_worth)}
                                          icon={'emojione-monotone:money-bag'}/>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <DashboardWidgetSummary title={`Expenses, ${monthYear.format("MMM YYYY")}`} total={fCurrency(dashboard.expenses)}
                                          icon={'emojione-monotone:money-with-wings'}/>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <DashboardWidgetSummary title={`Income, ${monthYear.format("MMM YYYY")}`} total={fCurrency(dashboard.income)}
                                          icon={'dashicons:money-alt'}/>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <DashboardWidgetSummary title={`Net Income, ${monthYear.format("MMM YYYY")}`} total={fCurrency(dashboard.income - dashboard.expenses)}
                                          icon={'dashicons:money-alt'}/>
                    </Grid>

                    {dashboard && <>
                        <Grid item xs={12} md={12} lg={12}>
                            <SpendingByCategory
                                title="How much you spent daily"
                                subheader={`Your average daily spend in ${monthYear.format("MMM YYYY")} was ${fCurrency(dashboard.avg_daily_spending)}`}
                                chartData={dashboard.daily_spending || []}
                                chartColors={[
                                    theme.palette.primary.main,
                                    theme.palette.chart.blue[0],
                                    theme.palette.chart.violet[0],
                                    theme.palette.chart.yellow[0],
                                ]}
                            />
                        </Grid>
                    </>}

                    { dashboard && dashboard.monthly_snapshots && <>
                        <Grid item xs={12} md={12} lg={6}>
                        <FinancialSnapshotMonthly
                            title="Net worth"
                            subheader="Over the last 12 months"
                            chartLabels={dashboard.monthly_snapshots.map(snapshot => snapshot.date)}
                            chartData={[
                                {
                                    name: 'Net Worth',
                                    type: 'column',
                                    fill: 'solid',
                                    data: dashboard.monthly_snapshots.map(snapshot => snapshot.net_worth),
                                }
                            ]}
                        />
                    </Grid>

                    <Grid item xs={12} md={12} lg={6}>
                        <FinancialSnapshotMonthly
                        title="Income & expenses"
                        subheader="Over the last 12 months"
                        chartLabels={dashboard.monthly_snapshots.map(snapshot => snapshot.date)}
                        chartData={[
                            {
                                name: 'Income',
                                type: 'line',
                                fill: 'solid',
                                data: dashboard.monthly_snapshots.map(snapshot => snapshot.income),
                            },
                            {
                                name: 'Expenses',
                                type: 'area',
                                fill: 'gradient',
                                data: dashboard.monthly_snapshots.map(snapshot => snapshot.expenses),
                            },
                        ]}
                        />
                        </Grid>
                    </>
                    }

                    {dashboard && <>
                        <Grid item xs={12} md={12} lg={6}>
                            <SpendingByCategory
                                title="Your spending by category"
                                subheader={`For the month of ${monthYear.format("MMM YYYY")}`}
                                chartData={dashboard.spend_by_category || []}
                                chartColors={[
                                    theme.palette.primary.main,
                                    theme.palette.chart.blue[0],
                                    theme.palette.chart.violet[0],
                                    theme.palette.chart.yellow[0],
                                ]}
                            />
                        </Grid>
                    </>}

                    { dashboard && dashboard.top_n_expenses && <Grid item xs={12} md={12} lg={6}>
                        <AppOrderTimeline
                            title="Top 5 Transactions"
                            list={dashboard.top_n_expenses.map((expense, index) => ({
                                id: faker.datatype.uuid(),
                                title: expense.transaction_name,
                                amount: expense.amount,
                            })) || []}
                        />
                    </Grid>}

                    {dashboard && dashboard.sankey_data && <>
                        <Grid item xs={12} md={12} lg={12}>
                            <SankeyChart
                                title="Your spending by category (sankey format)"
                                subheader={`For the month of ${monthYear.format("MMM YYYY")}`}
                                chartData={
                                    {
                                        "nodes": dashboard.sankey_node_names.map(node => ({id: node})),
                                        "links": dashboard.sankey_data
                                    }
                                }
                            />
                        </Grid>
                    </>}

                    { SHOW_EXAMPLES && <Grid item xs={12} md={6} lg={8}>
                        <AppConversionRates
                            title="Conversion Rates"
                            subheader="(+43%) than last year"
                            chartData={[
                                {label: 'Italy', value: 400},
                                {label: 'Japan', value: 430},
                                {label: 'China', value: 448},
                                {label: 'Canada', value: 470},
                                {label: 'France', value: 540},
                                {label: 'Germany', value: 580},
                                {label: 'South Korea', value: 690},
                                {label: 'Netherlands', value: 1100},
                                {label: 'United States', value: 1200},
                                {label: 'United Kingdom', value: 1380},
                            ]}
                        />
                    </Grid> }

                    { SHOW_EXAMPLES && <Grid item xs={12} md={6} lg={4}>
                        <AppCurrentSubject
                            title="Current Subject"
                            chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
                            chartData={[
                                {name: 'Series 1', data: [80, 50, 30, 40, 100, 20]},
                                {name: 'Series 2', data: [20, 30, 40, 80, 20, 80]},
                                {name: 'Series 3', data: [44, 76, 78, 13, 43, 10]},
                            ]}
                            chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
                        />
                    </Grid>}

                    { SHOW_EXAMPLES && <Grid item xs={12} md={6} lg={8}>
                        <AppNewsUpdate
                            title="News Update"
                            list={[...Array(5)].map((_, index) => ({
                                id: faker.datatype.uuid(),
                                title: faker.name.jobTitle(),
                                description: faker.name.jobTitle(),
                                image: `/static/mock-images/covers/cover_${index + 1}.jpg`,
                                postedAt: faker.date.recent(),
                            }))}
                        />
                    </Grid>}

                    { SHOW_EXAMPLES && <Grid item xs={12} md={6} lg={4}>
                        <AppTrafficBySite
                            title="Traffic by Site"
                            list={[
                                {
                                    name: 'FaceBook',
                                    value: 323234,
                                    icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} height={32}/>,
                                },
                                {
                                    name: 'Google',
                                    value: 341212,
                                    icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} height={32}/>,
                                },
                                {
                                    name: 'Linkedin',
                                    value: 411213,
                                    icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} height={32}/>,
                                },
                                {
                                    name: 'Twitter',
                                    value: 443232,
                                    icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} height={32}/>,
                                },
                            ]}
                        />
                    </Grid>}

                    { SHOW_EXAMPLES && <Grid item xs={12} md={6} lg={8}>
                        <AppTasks
                            title="Tasks"
                            list={[
                                {id: '1', label: 'Create FireStone Logo'},
                                {id: '2', label: 'Add SCSS and JS files if required'},
                                {id: '3', label: 'Stakeholder Meeting'},
                                {id: '4', label: 'Scoping & Estimations'},
                                {id: '5', label: 'Sprint Showcase'},
                            ]}
                        />
                    </Grid>}
                </Grid>
            </Container>
        </Page>
    );
}
