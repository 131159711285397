import {useState, Fragment, useEffect} from 'react';
// material
import {
    Paper,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    ListItem,
    TextField,
    Divider,
    FormControlLabel, Checkbox,
} from '@mui/material';
import Iconify from "../../../components/Iconify";
import {Alert} from "@mui/lab";
import {FormProvider, RHFTextField} from "../../../components/hook-form";
import {useForm} from "react-hook-form";
import CategoryService from "../../../services/CategoryService";
// components

// ----------------------------------------------------------------------

export default function CategoryCreate({existingCategory, createCategoryModalOpen, setCreateCategoryModalOpen, refetchCategories, ...other}) {
    const [categoryName, setCategoryName] = useState();
    const [isIncome, setIsIncome] = useState();

    useEffect(() => {
        if (existingCategory) {
            setCategoryName(existingCategory.display_name);
            setIsIncome(existingCategory.is_income);
        } else {
            setCategoryName(null);
            setIsIncome(false);
        }
    }, [existingCategory, createCategoryModalOpen]);

    const handleModalClose = (changeMade = false) => {
        setCreateCategoryModalOpen(false);
        if (changeMade) {
            refetchCategories();
        }
    }

    const handleCreate = () => {
        CategoryService.createCategory({
            category_name: categoryName,
            is_income: isIncome
        }, response => {
            handleModalClose(true);
        }, error => {
           console.log(error);
        });
    }

    const handleUpdate = () => {
        CategoryService.updateCategory({
            category_id: existingCategory.id,
            category_name: categoryName,
            is_income: isIncome
        }, response => {
            handleModalClose(true);
        }, error => {
            console.log(error);
        });
    }

    return (
        <>
            <Dialog
                open={createCategoryModalOpen}
                onClose={handleModalClose}>
                <DialogTitle>
                    {existingCategory ? "Edit category" : "Create category"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {existingCategory ? "Edit the details of this category" : "Create a new category"}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="category"
                        label="Category"
                        type="text"
                        fullWidth
                        variant={"standard"}
                        value={categoryName}
                        onChange={(event) => setCategoryName(event.target.value)}
                        />
                    <br/>
                    <FormControlLabel control={
                        <Checkbox
                            value={isIncome}
                            checked={isIncome}
                            onChange={(event) => setIsIncome(event.target.checked)}
                        />
                    } label="Categorize as income" />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleModalClose}>Cancel</Button>
                    {
                        existingCategory ?
                            <Button onClick={handleUpdate}>Update</Button> :
                            <Button onClick={handleCreate}>Create</Button>
                    }
                </DialogActions>
            </Dialog>
        </>
    );
}
