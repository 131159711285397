import {useEffect, useRef, useState} from 'react';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
// @mui
import {alpha} from '@mui/material/styles';
import {Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton} from '@mui/material';
// components
import MenuPopover from '../../components/MenuPopover';
// mocks_
import AuthService from "../../services/AuthService";
import auth from "../../utils/auth";
import Iconify from "../../components/Iconify";
import UserService from "../../services/UserService";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
    {
        label: 'Home',
        icon: 'eva:home-fill',
        linkTo: '/',
    },
    // {
    //     label: 'Profile',
    //     icon: 'eva:person-fill',
    //     linkTo: '#',
    // },
    {
        label: 'Settings',
        icon: 'eva:settings-2-fill',
        linkTo: '/dashboard/settings',
    },
];

// ----------------------------------------------------------------------

export default function AccountPopover({ user }) {
    const navigate = useNavigate();

    const anchorRef = useRef(null);

    const [open, setOpen] = useState(null);

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    const handleLogout = () => {
        AuthService.logout(response => {
            auth.removeAuthToken();
            navigate('/login', { replace: true });
        }, error => {
            if (error.response.status === 401) {
                // todo should eventually do this for all 401 requests probably
                auth.removeAuthToken();
                navigate('/login', { replace: true });
                console.log("Unauthenticated trying to logout, returning to login")
            } else {
                console.log(error);
            }
        })
    }

    return (
        <>
            <IconButton
                ref={anchorRef}
                onClick={handleOpen}
                sx={{
                    p: 0,
                    padding: '8px',
                    ...(open && {
                        '&:before': {
                            zIndex: 1,
                            content: "''",
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            position: 'absolute',
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.3),
                        },
                    }),
                }}
            >
                <Iconify icon="material-symbols:menu"/>
            </IconButton>

            <MenuPopover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                sx={{
                    p: 0,
                    mt: 1.5,
                    ml: 0.75,
                    '& .MuiMenuItem-root': {
                        typography: 'body2',
                        borderRadius: 0.75,
                    },
                }}
            >
                <Box sx={{my: 1.5, px: 2.5}}>
                    <Typography variant="subtitle2" noWrap>
                        {user && (user.first_name + ' ' + user.last_name)}
                    </Typography>
                    <Typography variant="body2" sx={{color: 'text.secondary'}} noWrap>
                        {user && user.email}
                    </Typography>
                </Box>

                <Divider sx={{borderStyle: 'dashed'}}/>

                <Stack sx={{p: 1}}>
                    {MENU_OPTIONS.map((option) => (
                        <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Stack>

                <Divider sx={{borderStyle: 'dashed'}}/>

                <MenuItem onClick={handleLogout} sx={{m: 1}}>
                    Logout
                </MenuItem>
            </MenuPopover>
        </>
    );
}
