import {useEffect, useRef, useState} from 'react';
// material
import {alpha} from '@mui/material/styles';
import {
    Box,
    MenuItem,
    Stack,
    Table,
    Card,
    Typography,
    Button,
    Container,
    TableContainer,
    LinearProgress,
    TableHead, TableRow, TableCell, TableSortLabel, Tooltip
} from '@mui/material';
// plaid
import {
    usePlaidLink,
} from 'react-plaid-link';
// components
import MenuPopover from '../components/MenuPopover';
// services
import PlaidLinkService from "../services/PlaidLinkService";
import Page from "../components/Page";
import Iconify from "../components/Iconify";
import Scrollbar from "../components/Scrollbar";
import AccountsService from "../services/AccountsService";
import {fDate} from "../utils/formatTime";
import ItemService from "../services/ItemService";
import ConfirmationDialog from "../components/ConfirmationDialog";
import {fCurrency} from "../utils/formatNumber";
import {fCapitalizeAllWords} from "../utils/formatText";

// ----------------------------------------------------------------------

const visuallyHidden = {
    border: 0,
    margin: -1,
    padding: 0,
    width: '1px',
    height: '1px',
    overflow: 'hidden',
    position: 'absolute',
    whiteSpace: 'nowrap',
    clip: 'rect(0 0 0 0)',
};

const TABLE_HEAD = [
    { id: 'name', label: 'Name', alignRight: false},
    { id: 'date_created', label: 'Date Added', alignRight: false},
    { id: 'plaid_type', label: 'Type', alignRight: false},
    { id: 'plaid_balance_available', label: 'Available', alignRight: false},
    { id: 'plaid_balance_current', label: 'Balance', alignRight: false},
    { id: 'plaid_limit', label: 'Limit', alignRight: false},
    { id: ''},
]

export default function LinkInstitution() {
    const [linkToken, setLinkToken] = useState();
    const [updateModePlaidItemId, setUpdateModePlaidItemId] = useState(false);
    const [accounts, setAccounts] = useState({});
    const [itemIdToItem, setItemIdToItem] = useState({});

    const [selectedBankToUnlink, setSelectedBankToUnlink] = useState(null);

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [isLoading, setIsLoading] = useState(false);

    const getLinkToken = () => {
        PlaidLinkService.createLinkToken(response => {
            const linkToken = response.data.token;
            const itemIdRequiringLogin = response.data.item_id_requiring_login;
            setLinkToken(linkToken);
            setUpdateModePlaidItemId(itemIdRequiringLogin);
        });
    }

    const getAccounts = () => {
        AccountsService.getAccounts(response => {
            let accountsResponse = response.data.accounts;
            let accountsByItemId = {}
            let itemsByItemId = {}
            for (let accountIdx in accountsResponse) {
                let account = accountsResponse[accountIdx];
                if (!(account.internal_plaid_item.id in accountsByItemId)) {
                    accountsByItemId[account.internal_plaid_item.id] = [];
                    itemsByItemId[account.internal_plaid_item.id] = account.internal_plaid_item;
                }
                accountsByItemId[account.internal_plaid_item.id].push(account);
            }
            setAccounts(accountsByItemId);
            setItemIdToItem(itemsByItemId);
            getLinkToken();
        }, error => {
            console.log(error);
        })
    }

    const deleteItem = (internal_institution_id) => {
        setSelectedBankToUnlink(null);
        ItemService.deleteItem(internal_institution_id, ignored => {
            getAccounts();
        }, error => {
            console.log(error);
        })
    }

    useEffect(() => {
        getAccounts();
    }, [])

    const onLinkSuccess = (publicToken, metadata) => {
        if (!updateModePlaidItemId) {
            console.log("Public token: " + publicToken);
            console.log(metadata);
            console.log();
            let accounts = [];
            for (let account_idx in metadata.accounts) {
                accounts.push({
                    "name": metadata.accounts[account_idx].name,
                    "mask": metadata.accounts[account_idx].mask
                })
            }
            PlaidLinkService.exchangePublicToken({
                public_token: publicToken,
                institution_id: metadata.institution.institution_id,
                accounts: accounts,
            }, (success) => {
                console.log(success);
                console.log("Public token exchanged");
                getAccounts();
            }, (error) => {
                console.log(error);
            })
        } else {
            ItemService.itemReauthenticationSuccessful(updateModePlaidItemId, response => {
                setUpdateModePlaidItemId(null);
                getAccounts();
            }, error => {
                console.log(error);
            })
        }
    }

    const onLinkExit = (error, metadata) => {
        console.log("Error: " + error);
        console.log(metadata);
        console.log();
    }

    const onLinkEvent = (eventName, metadata) => {
        console.log("Event: " + eventName);
        console.log(metadata);
        console.log();
    }

    const { open, ready } = usePlaidLink({
        token: linkToken,
        onSuccess: onLinkSuccess,
        onExit: onLinkExit,
        onEvent: onLinkEvent,

    })

    return (
        <Page title="Linked Accounts">
            <Container maxWidth={"xl"}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={4}>
                    <Typography variant="h4" gutterBottom>
                        Linked Accounts
                    </Typography>
                    {
                        updateModePlaidItemId ?
                            <Button variant="contained"
                                    onClick={open}
                                    disabled={!ready}
                                    color={"error"}
                                    startIcon={<Iconify icon="material-symbols:change-circle" />}>Reauthenticate</Button>
                            :
                            <Button variant="contained"
                                    onClick={open}
                                    disabled={!ready}
                                    startIcon={<Iconify icon="eva:plus-fill" />}>New</Button>
                    }
                </Stack>

                <Card>
                    <Scrollbar>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {TABLE_HEAD.map((headCell) => (
                                            <TableCell
                                                key={headCell.id}
                                                align={headCell.alignRight ? 'right' : 'left'}
                                                sortDirection={orderBy === headCell.id ? order : false}>
                                                <TableSortLabel
                                                    hideSortIcon
                                                    active={orderBy === headCell.id}
                                                    direction={orderBy === headCell.id ? order : 'asc'}
                                                    onClick={false/*tbd*/}>
                                                    {headCell.label}
                                                    {orderBy === headCell.id ? (
                                                        <Box sx={{ ...visuallyHidden }}>
                                                            { order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                        </Box>
                                                    ) : null}
                                                </TableSortLabel>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>

                                {
                                    Object.entries(accounts).map(([item_id, accounts]) => {
                                        let itemObject = itemIdToItem[item_id];
                                        return <>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        {itemObject.plaid_institution_name ? itemObject.plaid_institution_name : `Bank ID ${item_id}`}
                                                    </TableCell>

                                                    {/* len(TABLE_HEAD) - 2 times*/}
                                                    <TableCell></TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell></TableCell>

                                                    <TableCell
                                                        align={"right"}
                                                        >
                                                        {
                                                            itemObject.requires_login &&
                                                            <Button disabled
                                                                startIcon={
                                                                <Iconify icon="material-symbols:error-circle-rounded" />}>
                                                                Sync disabled. Requires reauthentication.
                                                            </Button>
                                                        }
                                                        &nbsp;

                                                        <Tooltip title="Unlinking this bank will remove all accounts under it.">
                                                            <Button variant={"contained"}
                                                                    onClick={() => setSelectedBankToUnlink(item_id)}
                                                                    color={'error'}
                                                                    startIcon={<Iconify icon="ic:baseline-remove-circle" />}>Unlink</Button>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            { accounts.map(account => {
                                                const {id, date_created, plaid_name, subtype, plaid_balance_available, plaid_balance_current, plaid_limit } = account;

                                                return (
                                                    <TableRow
                                                        hover
                                                        key={id}
                                                        tabIndex={-1}
                                                    >
                                                        <TableCell>
                                                            {plaid_name}
                                                        </TableCell>
                                                        <TableCell>
                                                            {fDate(date_created)}
                                                        </TableCell>
                                                        <TableCell>
                                                            {fCapitalizeAllWords(subtype)}
                                                        </TableCell>
                                                        <TableCell>
                                                            {plaid_balance_available >= 0 && fCurrency(plaid_balance_available)}
                                                        </TableCell>
                                                        <TableCell>
                                                            {fCurrency(plaid_balance_current)}
                                                        </TableCell>
                                                        <TableCell>
                                                            {plaid_limit >= 0 && fCurrency(plaid_limit)}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                            }
                                        </>
                                    })
                                }

                            </Table>
                        </TableContainer>
                    </Scrollbar>
                </Card>

                <ConfirmationDialog isOpen={selectedBankToUnlink != null}
                                    titleText={"Are you sure you want to unlink this bank?"}
                                    dialogText={"All accounts and transactions from this bank will be deleted."}
                                    confirmButtonText={"Unlink"}
                                    denyButtonText={"Cancel"}
                                    onConfirm={() => deleteItem(selectedBankToUnlink)}
                                    onDeny={() => setSelectedBankToUnlink(null)}/>

                { isLoading && <LinearProgress />}
            </Container>
        </Page>
    );
}
