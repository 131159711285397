import ApiService from "./ApiService";
import AuthService from "./AuthService";

export default {
    createLinkToken(success, failure) {
        ApiService.get('/get_link_token', AuthService.getHeaders()).then(success).catch(failure);
    },
    exchangePublicToken(data, success, failure) {
        ApiService.post('/exchange_public_token', data, AuthService.getHeaders()).then(success).catch(failure);
    }
}