import ApiService from "./ApiService";
import auth from "../utils/auth";

export default {
    login(email, password, success, failure) {
        let form = new FormData();
        form.append('email', email);
        form.append('password', password);
        ApiService.post('/token/login', form, {}).then(success).catch(failure);
    },

    logout(success, failure) {
        ApiService.post('/token/logout', {}, this.getHeaders()).then(success).catch(failure);
    },

    getHeaders() {
        const token = auth.getAuthToken();
        return {
            headers: {
                Authorization: `Token ${token}`
            }
        }
    }
}