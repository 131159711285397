import PropTypes from 'prop-types';
// material
import {
    Box,
    Checkbox, Collapse, Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
import Iconify from "../../../components/Iconify";
import {useState} from "react";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import Button from "@mui/material/Button";
import {fCurrency} from "../../../utils/formatNumber";
import BudgetsService from "../../../services/BudgetsService";
import BudgetCreateUpdate from "./BudgetCreateUpdate";
import BudgetProgressBar from "./BudgetProgressBar";

// ----------------------------------------------------------------------

BudgetsList.propTypes = {
    budgets: PropTypes.array.isRequired
};

export default function BudgetsList({categories, budgets, refreshPage, ...other}) {
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [deleteBudgetConfirmationOpen, setDeleteBudgetConfirmationOpen] = useState(false);
    const [selectedBudget, setSelectedBudget] = useState(null);

    const handleBudgetDelete = (budget) => {
        BudgetsService.deleteBudget(budget.id, response => {
            setDeleteBudgetConfirmationOpen(false);
            setSelectedBudget(null);
            refreshPage();
        }, error => {
            console.log(error);
        });
    }

    const selectBudgetAndShowUpdateModal = (budget) => {
        setSelectedBudget(budget);
        setEditModalOpen(true);
    }

    const selectBudgetAndShowDeleteConfirmation = (budget) => {
        setSelectedBudget(budget);
        setDeleteBudgetConfirmationOpen(true);
    }

    return (
        <>
            <List
                sx={{width: '100%', bgcolor: 'background.paper'}}
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
                {budgets.map((budget, budgetIdx) => (
                    <>
                        <ListItem key={"budget" + budgetIdx}>
                            <ListItemText primary={budget.category_details.display_name}
                                          secondary={
                                                <>
                                                    <Box component="span" sx={{color: 'text.secondary'}}>
                                                        Monthly budget: {fCurrency(budget.limit)}
                                                    </Box>
                                                    &nbsp; | &nbsp;
                                                    <Box component="span" sx={{color: 'text.secondary'}}>
                                                        Spent: {fCurrency(budget.spent)}
                                                    </Box>
                                                    &nbsp; | &nbsp;
                                                    <Box component="span" sx={{color: 'text.secondary'}}>
                                                        {budget.limit - budget.spent < 0 ?
                                                            "Over budget by" : "Remaining"}: {fCurrency(Math.abs(budget.limit - budget.spent))}
                                                    </Box>
                                                </>
                                          }/>

                            <ListItemIcon>
                                <Button onClick={() => selectBudgetAndShowUpdateModal(budget)}
                                >
                                    Edit
                                </Button>
                            </ListItemIcon>
                            &nbsp;
                            <ListItemIcon>
                                <Button onClick={() => selectBudgetAndShowDeleteConfirmation(budget)}
                                        color={"error"}
                                >
                                    Delete
                                </Button>
                            </ListItemIcon>
                        </ListItem>
                        <Box sx={{ mx: 2 }}>
                            <BudgetProgressBar spent={budget.spent} limit={budget.limit}/>
                        </Box>
                        <br/>
                        { budgetIdx < budgets.length - 1 && <Divider />}
                    </>
                ))}

                {
                    budgets.length === 0 && <>
                        <ListItem key={"budget0"}>
                            <ListItemText primary={"You have no budgets created."}
                                          secondary={"Add a monthly budget for your categories here!"}/>

                        </ListItem>
                    </>
                }
            </List>
            <ConfirmationDialog isOpen={deleteBudgetConfirmationOpen}
                                titleText={"Delete budget"}
                                dialogText={"Are you sure you want to delete this budget?"}
                                confirmButtonText={"Delete"}
                                denyButtonText={"Cancel"}
                                onConfirm={() => handleBudgetDelete(selectedBudget)}
                                onDeny={() => setDeleteBudgetConfirmationOpen(false)}/>
            <BudgetCreateUpdate createEditModalOpen={editModalOpen}
                                setCreateEditModalOpen={setEditModalOpen}
                                existingBudget={selectedBudget}
                                categories={categories}
                                refetchBudgets={refreshPage}/>
        </>
    );
}
