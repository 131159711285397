import {
    Autocomplete, Box,
    Card,
    Checkbox,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer, TablePagination,
    TableRow, TextField, Tooltip,
    Typography
} from "@mui/material";
import {UserListHead, UserListToolbar, UserMoreMenu} from "./index";
import Scrollbar from "../../../components/Scrollbar";
import {fDate} from "../../../utils/formatTime";
import {fCurrency} from "../../../utils/formatNumber";
import Label from "../../../components/Label";
import {sentenceCase} from "change-case";
import SearchNotFound from "../../../components/SearchNotFound";
import {useEffect, useState} from "react";
import TransactionsService from "../../../services/TransactionsService";
import CategoryRuleModal from "./CategoryRuleModal";
import Iconify from "../../../components/Iconify";

const TABLE_HEAD = [
    {id: 'transaction_date', label: 'Date', alignRight: false},
    {id: 'plaid_merchant_name', label: 'Payee', alignRight: false},
    {id: 'transaction_name', label: 'Name', alignRight: false},
    {id: 'amount', label: 'Amount', alignRight: false},
    {id: 'category', label: 'Category', alignRight: false},
    {id: ''},  // is_pending
];

export default function TransactionMainTable({
    setSelected,
    selected,
    selectedTransaction,
    setSelectedTransaction,
    categories,
    setCreateTransactionModalOpen,
    filterName,
    refresh,
    setRefresh
}) {
    const [transactions, setTransactions] = useState([]);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('transaction_date');
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [page, setPage] = useState(0);
    const [totalTransactions, setTotalTransactions] = useState(0);
    const [categoryRuleModalOpen, setCategoryRuleModalOpen] = useState(false);

    useEffect(() => {
        setRefresh(true);
    }, [page, rowsPerPage])

    const handleCategoryRuleModalClose = (changeMade = false) => {
        setCategoryRuleModalOpen(false);
        setSelectedTransaction(null);
        if (changeMade) {
            setRefresh(true);
        }
    }

    useEffect(() => {
        if (refresh) {
            getTransactions();
        }
        if (filterName.length > 2) {
            searchTransactions(filterName);
        }
    }, [refresh]);

    useEffect(() => {
        if (filterName.length > 2) { // if filter is valid (>= 3 chars) then search for transactions
            searchTransactions(filterName);
        } else { // otherwise if filter is less than 3 chars, just get all transactions
            searchTransactions("");
        }
    }, [filterName]);

    const searchTransactions = (query) => {
        TransactionsService.searchTransactions(query, orderBy, order, rowsPerPage, page + 1, response => {
            let typed_transactions = [];
            setTotalTransactions(response.data.count);
            response.data.results.forEach(transaction => {
                transaction.transaction_date = new Date(transaction.transaction_date);
                typed_transactions.push(transaction);
            })
            setTransactions(typed_transactions);
            setRefresh(false);
        }, error => {
            console.log(error);
            setRefresh(false);
        })
    }

    const getTransactions = () => {
        // todo: should find a way to call getTransactions only once in the app, and have it be available in all components (optimization?)
        searchTransactions(filterName, orderBy, order, rowsPerPage, page + 1);
    }

    const onEditTransaction = (transaction) => {
        setSelectedTransaction(transaction);
        setCreateTransactionModalOpen(true);
    }

    const onDeleteTransaction = (transaction) => {
        TransactionsService.deleteTransaction(transaction.id, response => {
            getTransactions();
        }, error => {
            console.log(error);
        })
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        getTransactions();
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            setSelected(transactions);
            return;
        }
        setSelected([]);
    };

    const onCreateCategoryRule = (transaction) => {
        handleCategoryRuleModalOpen(transaction);
    }


    const handleCategoryRuleModalOpen = (transaction) => {
        setSelectedTransaction(transaction);
        setCategoryRuleModalOpen(true);
    }

    // const emptyRows = page > 0 ? Math.max(0, rowsPerPage - transactions.length) : 0;

    const isTransactionNotFound = transactions.length === 0;

    const updateTransactionCategory = (transaction, category) => {
        if (category === null || category === undefined) {
            // disallow unsetting category
            return;
        }

        TransactionsService.updateTransaction({
            transaction: {
                id: transaction.id,
                category_id: category.id
            }
        }, response => {
            let updatedTransaction = response.data.transaction;
            updatedTransaction.transaction_date = new Date(updatedTransaction.transaction_date);

            let transactionsCopy = [...transactions];
            let indexToUpdate;

            for (let transactionIndex in transactionsCopy) {
                if (transactionsCopy[transactionIndex].id === updatedTransaction.id) {
                    indexToUpdate = transactionIndex;
                    break;
                }
            }

            transactionsCopy[indexToUpdate] = updatedTransaction;

            setTransactions(transactionsCopy);
        }, error => {
            console.log(error);
        })
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const handleClick = (event, transaction) => {
        const selectedIndex = selected.indexOf(transaction);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, transaction);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    return (
        <>
            <Scrollbar>
                <TableContainer sx={{minWidth: 800}}>
                    <Table>
                        <UserListHead
                            order={order}
                            orderBy={orderBy}
                            headLabel={TABLE_HEAD}
                            rowCount={transactions.length}
                            numSelected={selected.length}
                            onRequestSort={handleRequestSort}
                            onSelectAllClick={handleSelectAllClick}
                        />
                        <TableBody>
                            {transactions.map((row) => {
                                const {
                                    id,
                                    transaction_date,
                                    plaid_merchant_name,
                                    transaction_name,
                                    amount,
                                    iso_currency_code,
                                    display_iso_currency_amount,
                                    is_pending,
                                    category,
                                    ai_categorized,
                                } = row;
                                const isItemSelected = selected.indexOf(row) !== -1;

                                return (
                                    <TableRow
                                        hover
                                        key={id}
                                        tabIndex={-1}
                                        role="checkbox"
                                        selected={isItemSelected}
                                        aria-checked={isItemSelected}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox checked={isItemSelected}
                                                      onChange={(event) => handleClick(event, row)}/>
                                        </TableCell>
                                        <TableCell component="th" scope="row" padding="none">
                                            <Stack direction="row" alignItems="center" spacing={2}>
                                                {/*<Avatar alt={name} src={avatarUrl} />*/}
                                                <Typography variant="subtitle2" noWrap>
                                                    {fDate(transaction_date)}
                                                </Typography>
                                            </Stack>
                                        </TableCell>
                                        <TableCell align="left">{plaid_merchant_name}</TableCell>
                                        <TableCell align="left">{transaction_name}</TableCell>
                                        <TableCell align="left">
                                            { amount != display_iso_currency_amount &&
                                                <Tooltip title={fCurrency(amount) + " " + iso_currency_code}>
                                                    <div>{fCurrency(display_iso_currency_amount)}*</div>
                                                </Tooltip>
                                            }
                                            {amount == display_iso_currency_amount &&
                                                <div>{fCurrency(display_iso_currency_amount)}</div>
                                            }
                                        </TableCell>
                                        <TableCell align="left">
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Autocomplete
                                                disablePortal
                                                selectOnFocus
                                                handleHomeEndKeys
                                                autoHighlight
                                                id="transaction-category"
                                                options={categories}
                                                getOptionLabel={(option) => option.display_name}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                sx={{ width: 175 }}
                                                value={category}
                                                onChange={(event, newCategory) => {
                                                    updateTransactionCategory(row, newCategory);
                                                }}
                                                renderInput={(params) => {
                                                    return <TextField {...params}
                                                                      variant="standard"/>;
                                                }}
                                            />
                                            { ai_categorized && category &&
                                                <Box sx={{ display: 'flex', alignItems: 'center', ml: 1, mt: 1 }}>
                                                    <Iconify
                                                        icon="fluent:sparkle-28-regular"
                                                        sx={{
                                                            width: 18,
                                                            height: 18,
                                                            color: 'primary.main',
                                                            mr: 0.5,
                                                            opacity: 0.9,
                                                        }}
                                                    />
                                                    <Typography
                                                        variant="caption"
                                                        sx={{
                                                            fontStyle: 'italic',
                                                            color: 'primary.main',
                                                            fontSize: '0.8rem',
                                                            fontWeight: 700,
                                                            opacity: 0.8,
                                                        }}
                                                    >
                                                        Suggested
                                                    </Typography>
                                                </Box> }
                                            </Box>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Label variant="ghost" color={(is_pending ? 'error' : 'success')}>
                                                {sentenceCase(is_pending ? 'pending' : 'completed')}
                                            </Label>
                                        </TableCell>

                                        <TableCell align="right">
                                            <UserMoreMenu
                                                onCreateCategoryRule={() => onCreateCategoryRule(row)}
                                                onEditTransaction={() => onEditTransaction(row)}
                                                onDeleteTransaction={() => onDeleteTransaction(row)}/>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {/*{emptyRows > 0 && (*/}
                            {/*    <TableRow style={{height: 53 * emptyRows}}>*/}
                            {/*        <TableCell colSpan={6}/>*/}
                            {/*    </TableRow>*/}
                            {/*)}*/}
                        </TableBody>

                        {isTransactionNotFound && (
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center" colSpan={6} sx={{py: 3}}>
                                        <SearchNotFound searchQuery={filterName}
                                                        allRowsAreEmpty={transactions.length === 0}/>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </Scrollbar>

            <TablePagination
                rowsPerPageOptions={[25, 50, 75, 100]}
                component="div"
                count={totalTransactions}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

            {/* todo can this be moved to TransactionMoreMenu.js too? */}
            <CategoryRuleModal isOpen={categoryRuleModalOpen}
                               handleClose={handleCategoryRuleModalClose}
                               transaction={selectedTransaction}
                               categories={categories}
            />
        </>
    )
}