import * as Yup from 'yup';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
// form
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
// @mui
import {Stack, IconButton, InputAdornment, TextField, Autocomplete} from '@mui/material';
import {LoadingButton} from '@mui/lab';
// components
import Iconify from '../../../components/Iconify';
import {FormProvider, RHFTextField} from '../../../components/hook-form';
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import UserService from "../../../services/UserService";

// ----------------------------------------------------------------------

export default function RegisterForm({ locationState }) {
    const navigate = useNavigate();

    // const [dateOfBirth, setDateOfBirth] = useState();
    const [displayCurrency, setDisplayCurrency] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const RegisterSchema = Yup.object().shape({
        firstName: Yup.string().required('First name required'),
        lastName: Yup.string().required('Last name required'),
        email: Yup.string().email('Email must be a valid email address').required('Email is required'),
        password: Yup.string().required('Password is required'),
        // city: Yup.string().optional(),
        // country: Yup.string().optional(),
    });

    const defaultValues = {
        firstName: '',
        lastName: '',
        email: locationState && locationState.email ? locationState.email : '',
        password: '',
        // city: '',
        // country: '',
    };

    const methods = useForm({
        resolver: yupResolver(RegisterSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        formState: {isSubmitting},
    } = methods;

    const onSubmit = async (data) => {
        UserService.signUp({
            first_name: data.firstName,
            last_name: data.lastName,
            email: data.email,
            password: data.password,
            // city: data.city,
            // country: data.country,
            display_iso_currency_code: displayCurrency,
        }, response => {
            console.log(response)
            navigate('/login', {replace: true, state: { email: response.data.email }});
        }, error => {
            console.log(error)
        })
    };

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
                <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                    <RHFTextField name="firstName" label="First name"/>
                    <RHFTextField name="lastName" label="Last name"/>
                </Stack>

                <RHFTextField name="email" label="Email address"/>

                <RHFTextField
                    name="password"
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}/>
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />

                {/*<LocalizationProvider dateAdapter={AdapterDayjs}>*/}
                {/*    <DatePicker*/}
                {/*        name="dateOfBirth"*/}
                {/*        views={['year', 'month']}*/}
                {/*        label="Date of Birth"*/}
                {/*        minDate={dayjs('1900-01-01')}*/}
                {/*        value={dateOfBirth}*/}
                {/*        onChange={(newDate) => setDateOfBirth(newDate)}*/}
                {/*        renderInput={(params) => <TextField name="dateOfBirth" {...params} helperText={null}/>}*/}
                {/*     />*/}
                {/*</LocalizationProvider>*/}

                {/*<Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>*/}
                {/*    <RHFTextField name="city" label="City"/>*/}
                {/*    <RHFTextField name="country" label="Country"/>*/}
                {/*</Stack>*/}

                <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                    <Autocomplete
                        disablePortal
                        selectOnFocus
                        handleHomeEndKeys
                        autoHighlight
                        fullWidth={true}
                        id="display-currency"
                        options={["USD", "CAD"]}
                        getOptionLabel={(option) => option}
                        isOptionEqualToValue={(option, value) => option === value}
                        value={displayCurrency}
                        onChange={(event, newCategory) => {
                            setDisplayCurrency(newCategory);
                        }}
                        renderInput={(params) => {
                            return <TextField label={"Display currency"}
                                              {...params} />;
                        }}
                    />
                </Stack>

                <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
                    Register
                </LoadingButton>
            </Stack>
        </FormProvider>
    );
}
