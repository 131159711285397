import {useEffect, useState} from 'react';
// material
import {Button, Card, Container, Stack, Typography} from '@mui/material';
// components
import Page from '../components/Page';
import {CategoriesList, CategoriesSort, CategoryCreate} from '../sections/@dashboard/categories';
// mock
import PRODUCTS from '../_mock/products';
import CategoryService from "../services/CategoryService";
import Iconify from "../components/Iconify";

// ----------------------------------------------------------------------

export default function Categories() {

    const [categories, setCategories] = useState([]);
    const [categoryRules, setCategoryRules] = useState([]);
    const [createCategoryModalOpen, setCreateCategoryModalOpen] = useState(false);

    useEffect(() => {
        refreshPage();
    }, []);

    const refreshPage = () => {
        getCategories();
        getCategoryRules();
    }

    const getCategories = () => {
        CategoryService.getCategories(response => {
            setCategories(response.data.categories);
        }, error => {
            console.log(error);
        })
    }

    const getCategoryRules = () => {
        CategoryService.getCategoryRules(response => {
            let categoryRulesCleanedUp = {};
            for (let idx in response.data.category_rules) {
                let rule = response.data.category_rules[idx];
                if (rule.category in categoryRulesCleanedUp) {
                    categoryRulesCleanedUp[rule.category].push(rule);
                } else {
                    categoryRulesCleanedUp[rule.category] = [rule];
                }
            }
            setCategoryRules(categoryRulesCleanedUp);
        }, error => {
            console.log(error);
        })
    }

    const onSortSelected = (sort) => {
        let sortedCategories;
        switch (sort) {
            case 'alphabetical':
                sortedCategories = [...categories].sort((a, b) => a.display_name.localeCompare(b.display_name));
                break;
            // case 'spendingDesc': // todo enable when spending is available in categories
            //     sortedCategories = [...categories].sort((a, b) => b.spending - a.spending);
            //     break;
            // case 'spendingAsc':
            //     sortedCategories = [...categories].sort((a, b) => a.spending - b.spending);
            //     break;
            default:
                sortedCategories = [...categories].sort((a, b) => a.id - b.id);
        }
        setCategories(sortedCategories);
    }

    return (
        <Page title="Categories">
            <Container maxWidth={"xl"}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Categories
                    </Typography>
                    <Button variant="contained"
                            onClick={() => setCreateCategoryModalOpen(true)}
                            startIcon={<Iconify icon="eva:plus-fill"/>}>
                        New Category
                    </Button>
                    <CategoryCreate createCategoryModalOpen={createCategoryModalOpen}
                                    setCreateCategoryModalOpen={setCreateCategoryModalOpen}
                                    refetchCategories={refreshPage}/>
                </Stack>

                <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
                    <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
                        <CategoriesSort onSortSelected={onSortSelected}/>
                    </Stack>
                </Stack>

                <Card>
                    <CategoriesList categories={categories} categoryRules={categoryRules} refreshPage={refreshPage}/>
                </Card>
            </Container>
        </Page>
    );
}
