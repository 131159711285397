import Cookies from 'js-cookie';

const LOCAL_STORAGE_AUTH_TOKEN_KEY = 'AUTH_KEY';
const LOCAL_STORAGE_AUTH_USER_ID = 'AUTH_USER_ID';

export default {

    // Auth Tokens
    setAuthToken(token) {
        Cookies.set(LOCAL_STORAGE_AUTH_TOKEN_KEY, token);
    },

    getAuthToken() {
        return Cookies.get(LOCAL_STORAGE_AUTH_TOKEN_KEY);
    },

    removeAuthToken() {
        Cookies.remove(LOCAL_STORAGE_AUTH_TOKEN_KEY);
    },

    // User ID
    setUserID(id) {
        localStorage.setItem(LOCAL_STORAGE_AUTH_USER_ID, id);
    },

    getUserID() {
        return localStorage.getItem(LOCAL_STORAGE_AUTH_USER_ID);
    },

    removeUserID() {
        localStorage.removeItem(LOCAL_STORAGE_AUTH_USER_ID);
    },

    // General
    setUserData(userData) {
        // set remaining items
        localStorage.setItem('USER_DATA', JSON.stringify(userData));
    },

    getUserData() {
        return JSON.parse(localStorage.getItem('USER_DATA'));
    },

    removeAll() {
        this.removeAuthToken();
        this.removeUserID();
        localStorage.removeItem('USER_DATA');
    }
}