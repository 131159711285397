import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, Box } from '@mui/material';
import { ResponsiveSankey } from '@nivo/sankey';
import {fCurrency} from "../../../utils/formatNumber";

// Error Boundary Component
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    render() {
        if (this.state.hasError) {
            return <h1>Something went wrong with the chart.</h1>;
        }
        return this.props.children;
    }
}

// Data Validation Function
function validateSankeyData(data) {
    if (!data || !data.nodes || !data.links) {
        console.error('Invalid data structure for Sankey chart');
        return { nodes: [], links: [] };
    }

    return {
        nodes: data.nodes.map(node => ({
            ...node,
            id: String(node.id), // Ensure id is a string
            value: Number(node.value) || 0 // Ensure value is a number
        })),
        links: data.links.filter(link =>
            typeof link.source === 'string' &&
            typeof link.target === 'string' &&
            !isNaN(Number(link.value)) &&
            Number(link.value) > 0 // Filter out links with value <= 0
        ).map(link => ({
            ...link,
            value: Number(link.value)
        }))
    };
}

const CHART_HEIGHT = 600;

SankeyChart.propTypes = {
    title: PropTypes.string,
    subheader: PropTypes.string,
    chartData: PropTypes.shape({
        nodes: PropTypes.arrayOf(PropTypes.object),
        links: PropTypes.arrayOf(PropTypes.object)
    }),
};

const CustomLinkTooltip = ({ link }) => {
    const category = link.target.id;
    const amount = fCurrency(link.value);

    return (
        <div style={{
            background: 'white',
            padding: '8px 12px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            fontSize: '12px',
            fontFamily: 'Arial, sans-serif',
        }}>
            <div style={{ marginBottom: '4px', color: '#666' }}>
                {category}
            </div>
            <div style={{ color: '#666' }}>
                {amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </div>
        </div>
    );
};

export default function SankeyChart({ title, subheader, chartData, ...other }) {
    const validatedData = validateSankeyData(chartData);

    console.log('Validated Sankey Data:', validatedData);

    return (
        <ErrorBoundary>
            <Card {...other}>
                <CardHeader title={title}  subheader={subheader} />

                <Box sx={{ height: CHART_HEIGHT, p: 3, pb: 1 }} dir="ltr">
                    <ResponsiveSankey
                        data={validatedData}
                        margin={{ top: 40, right: 160, bottom: 40, left: 80 }}
                        align="justify"
                        colors={{ scheme: 'category10' }}
                        nodeOpacity={1}
                        nodeThickness={6}
                        nodeHoverOthersOpacity={0.35}
                        nodeSpacing={24}
                        nodeBorderWidth={0}
                        nodeBorderColor={{
                            from: 'color',
                            modifiers: [['darker', 0.8]]
                        }}
                        nodeBorderRadius={3}
                        linkOpacity={0.5}
                        linkHoverOthersOpacity={0.1}
                        linkContract={0}
                        linkTooltip={CustomLinkTooltip}
                        enableLinkGradient={true}
                        labelPosition="inside"
                        labelOrientation="horizontal"
                        labelPadding={12}
                        labelTextColor={{
                            from: 'color',
                            modifiers: [['darker', 1]]
                        }}
                        legends={[
                            {
                                anchor: 'bottom-right',
                                direction: 'column',
                                translateX: 130,
                                itemWidth: 100,
                                itemHeight: 14,
                                itemDirection: 'left-to-right',
                                itemsSpacing: 2,
                                itemTextColor: '#999',
                                symbolSize: 14,
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemTextColor: '#000'
                                        }
                                    }
                                ]
                            }
                        ]}
                    />
                </Box>
            </Card>
        </ErrorBoundary>
    );
}