import PropTypes from 'prop-types';
// material
import {
    Checkbox, Collapse, Divider,
    FormControlLabel, FormGroup,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader, Tooltip
} from '@mui/material';
import CategoryCard from "./CategoryCard";
import Iconify from "../../../components/Iconify";
import CategoryService from "../../../services/CategoryService";
import CategoryRuleModal from "../transactions/CategoryRuleModal";
import {useState} from "react";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import Button from "@mui/material/Button";
import {CategoryCreate} from "./index";

// ----------------------------------------------------------------------

CategoriesList.propTypes = {
    categories: PropTypes.array.isRequired
};

export default function CategoriesList({categories, categoryRules, refreshPage, ...other}) {
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [categoryRuleModalOpen, setCategoryRuleModalOpen] = useState(false);
    const [deleteCategoryConfirmationOpen, setDeleteCategoryConfirmationOpen] = useState(false);
    const [deleteRuleConfirmationOpen, setDeleteRuleConfirmationOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedActionRule, setSelectedActionRule] = useState(null);


    const selectCategoryAndShowUpdateModal = (category) => {
        setSelectedCategory(category);
        setEditModalOpen(true);
    }

    const selectCategoryAndShowDeleteConfirmation = (category) => {
        setSelectedCategory(category);
        setDeleteCategoryConfirmationOpen(true);
    }

    const handleCategoryRuleModalOpen = (rule) => {
        setSelectedActionRule(rule);
        setCategoryRuleModalOpen(true);
    }

    const handleCategoryRuleDelete = (rule) => {
        setSelectedActionRule(rule);
        setDeleteRuleConfirmationOpen(true);
    }

    const handleCategoryRuleModalClose = (changeMade = false) => {
        setCategoryRuleModalOpen(false);
        setSelectedActionRule(null);
        if (changeMade) {
            refreshPage();
        }
    }

    const handleRuleDelete = (rule) => {
        CategoryService.deleteCategoryRule(rule.id, response => {
            setDeleteRuleConfirmationOpen(false);
            refreshPage();
        }, error => {
            console.log(error);
        });
    }

    const handleCategoryDelete = (category) => {
        CategoryService.deleteCategory(category.id, response => {
            setDeleteCategoryConfirmationOpen(false);
            setSelectedCategory(null);
            refreshPage();
        }, error => {
            console.log(error);
        });
    }

    function getRuleLabel(rule) {
        let conditions = [];

        if (rule.start_day_of_month && rule.end_day_of_month) {
            conditions.push("days " + rule.start_day_of_month + " to " + rule.end_day_of_month);
        }

        if (rule.keywords_in_merchant_name && rule.keywords_in_merchant_name.length > 0) {
            conditions.push("merchant name is like (" + rule.keywords_in_merchant_name.join(", ") + ")");
        }

        if (rule.keywords_in_transaction_name && rule.keywords_in_transaction_name.length > 0) {
            conditions.push("transaction name is like (" + rule.keywords_in_transaction_name.join(", ") + ")");
        }

        if (rule.min_amount && rule.max_amount) {
            conditions.push("between $" + rule.min_amount + " and $" + rule.max_amount);
        }

        return conditions.join(", ");
    }

    // TODO if income category show a green $+ symbol, if expense category show a red $- symbol

    return (
        <List
            sx={{width: '100%', bgcolor: 'background.paper'}}
            component="nav"
            aria-labelledby="nested-list-subheader"
        >
            {categories.filter(category => !category.date_deleted).map((category, categoryIdx) => (
                <>
                    <ListItem key={categoryIdx}>
                        <ListItemText primary={category.display_name} primaryTypographyProps={{ fontWeight: 'bold' }}  secondary={category.modifier}/>
                        <ListItemIcon>
                            <Button onClick={() => selectCategoryAndShowUpdateModal(category)}
                            >
                                Edit
                            </Button>
                        </ListItemIcon>
                        &nbsp;
                        <ListItemIcon>
                            <Button onClick={() => selectCategoryAndShowDeleteConfirmation(category)}
                                    color={"error"}
                            >
                                Delete
                            </Button>
                        </ListItemIcon>
                    </ListItem>
                    { category.id in categoryRules &&
                        <>
                            {
                                categoryRules[category.id].map((rule, idx) => (
                                    <Collapse in={true} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            <ListItem sx={{ pl: 4 }}>

                                                <ListItemText primary={`If ${getRuleLabel(rule)}`} />

                                                {/* Modify rule*/}
                                                <ListItemIcon>
                                                    <Button onClick={() => handleCategoryRuleModalOpen(rule)}
                                                    >
                                                        Edit
                                                    </Button>
                                                </ListItemIcon>
                                                &nbsp;
                                                <ListItemIcon>
                                                    <Button onClick={() => handleCategoryRuleDelete(rule)}
                                                            color={"error"}
                                                            >
                                                        Delete
                                                    </Button>
                                                </ListItemIcon>
                                            </ListItem>
                                        </List>
                                    </Collapse>
                                ))
                            }
                        </>
                    }
                    { categoryIdx < categories.length - 1 && <Divider />}
                </>
            ))}
            <CategoryRuleModal isOpen={categoryRuleModalOpen}
                               handleClose={handleCategoryRuleModalClose}
                               rule={selectedActionRule}
                               categories={categories}
            />
            <ConfirmationDialog isOpen={deleteRuleConfirmationOpen}
                                titleText={"Delete rule"}
                                dialogText={"Are you sure you want to delete this rule?"}
                                confirmButtonText={"Delete"}
                                denyButtonText={"Cancel"}
                                onConfirm={() => handleRuleDelete(selectedActionRule)}
                                onDeny={() => setDeleteRuleConfirmationOpen(false)}/>
            <ConfirmationDialog isOpen={deleteCategoryConfirmationOpen}
                                titleText={"Delete category"}
                                dialogText={"Are you sure you want to delete this category? All relevant rules will be deleted."}
                                confirmButtonText={"Delete"}
                                denyButtonText={"Cancel"}
                                onConfirm={() => handleCategoryDelete(selectedCategory)}
                                onDeny={() => setDeleteCategoryConfirmationOpen(false)}/>
            <CategoryCreate createCategoryModalOpen={editModalOpen}
                            setCreateCategoryModalOpen={setEditModalOpen}
                            existingCategory={selectedCategory}
                            refetchCategories={refreshPage}/>
        </List>
    );
}
