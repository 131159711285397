import {useState} from 'react';
// material
import {Menu, Button, MenuItem, Typography} from '@mui/material';
// component
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const SORT_BY_OPTIONS = [
    {value: 'default', label: 'Default'},
    {value: 'alphabetical', label: 'Alphabetical'},
    // {value: 'spendingDesc', label: 'Spending: High-Low'}, // todo enable when spending is available in categories
    // {value: 'spendingAsc', label: 'Spending: Low-High'},
];

export default function CategoriesSort({onSortSelected}) {
    const [open, setOpen] = useState(null);
    const [selected, setSelected] = useState({value: 'default', label: 'Default'});

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = (option) => {
        setOpen(null);
        setSelected(option);
        onSortSelected(option.value);
    };

    return (
        <>
            <Button
                color="inherit"
                disableRipple
                onClick={handleOpen}
                endIcon={<Iconify icon={open ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'}/>}
            >
                Sort By:&nbsp;
                <Typography component="span" variant="subtitle2" sx={{color: 'text.secondary'}}>
                    {selected.label}
                </Typography>
            </Button>
            <Menu
                keepMounted
                anchorEl={open}
                open={Boolean(open)}
                onClose={handleClose}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
            >
                {SORT_BY_OPTIONS.map((option) => (
                    <MenuItem
                        key={option.value}
                        selected={option.value === selected.value}
                        onClick={() => handleClose(option)}
                        sx={{typography: 'body2'}}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}
