import {useState, Fragment, useEffect} from 'react';
// material
import {
    Paper,
    Button, Chip,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, ListItem, TextField, Divider, InputAdornment,
} from '@mui/material';
import Iconify from "../../../components/Iconify";
import {Alert} from "@mui/lab";
import {FormProvider, RHFTextField} from "../../../components/hook-form";
import {useForm} from "react-hook-form";
import AssetService from "../../../services/AssetService";
// components

// ----------------------------------------------------------------------

export default function AssetCreateUpdate({existingAsset, createEditModalOpen, setCreateEditModalOpen, refetchAssets, ...other}) {
    const [assetName, setAssetName] = useState();
    const [assetValue, setAssetValue] = useState();

    useEffect(() => {
        if (existingAsset) {
            setAssetName(existingAsset.name);
            setAssetValue(existingAsset.value);
        } else {
            setAssetName(null);
            setAssetValue(null);
        }
    }, [existingAsset, createEditModalOpen]);

    const handleAssetNameChange = (event) => {
        setAssetName(event.target.value);
    }

    const handleAssetValueChange = (event) => {
        setAssetValue(event.target.value);
    }

    const handleModalClose = (changeMade = false) => {
        setCreateEditModalOpen(false);
        if (changeMade) {
            refetchAssets();
        }
    }

    const handleCreate = () => {
        AssetService.createAsset({
            asset_name: assetName,
            asset_value: assetValue
        }, response => {
            handleModalClose(true);
        }, error => {
            console.log(error);
        });
    }

    const handleUpdate = () => {
        AssetService.updateAsset({
            asset_id: existingAsset.id,
            asset_name: assetName,
            asset_value: assetValue
        }, response => {
            handleModalClose(true);
        }, error => {
            console.log(error);
        });
    }

    return (
        <>
            <Dialog
                open={createEditModalOpen}
                onClose={handleModalClose}>
                <DialogTitle>
                    { existingAsset ? "Edit asset" : "Create asset"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        We include your assets in the net worth that is shown on the dashboard. Assets can include a car, your home, investments, savings, etc.
                    </DialogContentText>

                    <TextField
                        fullWidth
                        autoFocus
                        margin="dense"
                        id="asset_name"
                        label="Asset Name"
                        type="text"
                        variant="standard"
                        value={assetName}
                        onChange={handleAssetNameChange}
                    />
                    <br/>
                    <TextField
                        fullWidth
                        margin="dense"
                        id="asset_value"
                        label="Asset Value"
                        type="number"
                        variant="standard"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        value={assetValue}
                        onChange={handleAssetValueChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleModalClose}>Cancel</Button>
                    {
                        existingAsset ?
                            <Button onClick={handleUpdate}>Update</Button> :
                            <Button onClick={handleCreate}>Create</Button>
                    }
                </DialogActions>
            </Dialog>
        </>
    );
}
