import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import {Box, Card, CardHeader} from '@mui/material';
// utils
import {fCurrency, fNumber} from '../../../utils/formatNumber';
// components
import { BaseOptionChart } from '../../../components/chart';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 372;
const LEGEND_HEIGHT = 72;

const ChartWrapperStyle = styled('div')(({ theme }) => ({
    height: CHART_HEIGHT,
    marginTop: theme.spacing(5),
    '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
    '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
        overflow: 'visible',
    },
    '& .apexcharts-legend': {
        height: LEGEND_HEIGHT,
        alignContent: 'center',
        position: 'relative !important',
        borderTop: `solid 1px ${theme.palette.divider}`,
        top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
    },
}));

// ----------------------------------------------------------------------

SpendingByCategory.propTypes = {
    title: PropTypes.string,
    subheader: PropTypes.string,
    chartColors: PropTypes.arrayOf(PropTypes.string),
    chartData: PropTypes.array,
};

export default function SpendingByCategory({ title, subheader, chartColors, chartData, ...other }) {
    const chartLabels = chartData.map((i) => i.label);

    const chartSeries = chartData.map((i) => i.value);

    const chartOptions = merge(BaseOptionChart(), {
        plotOptions: {
            bar: { columnWidth: '16%' },
        },
        chart: {
            height: 364,
            type: 'bar',
        },
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: false,
        },
        xaxis: {
            categories: chartLabels,
        },
        yaxis: {
            labels: {
                formatter: (value) => {
                    return fCurrency(value);
                },
            },
        },
        tooltip: {
            marker: { show: false },
            y: {
                formatter: (seriesName) => fCurrency(seriesName),
                title: {
                    // don't remove, this gets rid of "series-1" from "series-1: $1000" in tooltips.
                    formatter: () => '',
                },
            },
        },
    });

    return (
        <Card {...other}>
            <CardHeader title={title} subheader={subheader} />

            <Box sx={{ p: 3, pb: 1 }} dir="ltr">
                <ReactApexChart type="bar" series={[{ data: chartSeries }]} options={chartOptions} height={364}/>
            </Box>
        </Card>
    );
}
