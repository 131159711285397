import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import {linearProgressClasses} from "@mui/material";

const BudgetProgressBar = ({ spent, limit }) => {
    // Calculate the percentage of the budget spent
    const percentage = Math.min((spent / limit) * 100 || 0, 100);

    function getColor(value) {
        if (value < 10) {
            return '#00aa00'; // Slightly darker green
        } else if (value < 20) {
            return '#33aa00';
        } else if (value < 30) {
            return '#66aa00';
        } else if (value < 40) {
            return '#99aa00';
        } else if (value < 50) {
            return '#ccaa00';
        } else if (value < 60) {
            return '#ccaa00'; // Darker Yellow
        } else if (value < 70) {
            return '#ddaa00'; // Even Darker Yellow
        } else if (value < 80) {
            return '#ff9900';
        } else if (value < 90) {
            return '#ff6600';
        } else if (value < 100) {
            return '#ff3300';
        } else {
            return '#ff0000'; // Red
        }
    }

    return (
        <div style={{ width: '100%' }}>
            <LinearProgress variant="determinate"
                            value={percentage}
                            sx={{
                                height: 10,
                                borderRadius: 5,
                                backgroundColor: '#e0e0e0', // Grey background for unused portion
                                [`& .${linearProgressClasses.bar}`]: {
                                    borderRadius: 5,
                                    backgroundColor: getColor(percentage), // Dynamic color based on progress
                                },
                            }}/>
        </div>
    );
};

export default BudgetProgressBar;