import axios from 'axios';
import { config } from '../utils/constants'

const BASE_URL = config.url.API_URL;

export default {
    get: function(endpoint, config) {
        return axios.get(BASE_URL + endpoint, config);
    },

    post: function (endpoint, data, config) {
        return axios.post(BASE_URL + endpoint, data, config);
    },

    put: function (endpoint, data, config) {
        return axios.put(BASE_URL + endpoint, data, config);
    },

    delete: function (endpoint, config) {
        return axios.delete(BASE_URL + endpoint, config);
    }
}
