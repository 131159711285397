import {Box, Button, IconContainerProps, Rating, Snackbar, Stack, TextField, Typography} from "@mui/material";
import {Alert} from "@mui/lab";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import {styled} from "@mui/material/styles";
import {useState} from "react";
import UserFeedbackService from "../../services/UserFeedbackService";
import PropTypes from "prop-types";
import {useLocation, useNavigate} from "react-router-dom";

export default function DashboardFeedbackArea() {
    const location = useLocation();

    const [satisfactionRating, setSatisfactionRating] = useState(null); // 1, 2, 3 [dissatisfied, neutral, satisfied]
    const [feedback, setFeedback] = useState('');

    const [signalAlertMessage, setSignalAlertMessage] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('success');

    const handleFeedbackSuccessClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlertMessage('');
        setAlertSeverity('success');
        setSignalAlertMessage(false);
    };

    const handleFeedbackSend = () => {
        if (satisfactionRating === null) {
            setAlertMessage('Please rate your satisfaction level before sending feedback!');
            setAlertSeverity('error');
            setSignalAlertMessage(true);
            return;
        }
        UserFeedbackService.createUserFeedback({
            feedback: feedback.length > 0 ? feedback : null,
            rating: satisfactionRating,
            page: location.pathname
        }, (response) => {
            setSatisfactionRating(null);
            setFeedback('');

            setAlertMessage('Feedback sent successfully!');
            setSignalAlertMessage(true);
        }, (error) => {
            console.log(error);
        });
    };

    const customIcons = {
        1: {
            icon: <SentimentVeryDissatisfiedIcon color="error" fontSize={"large"} />,
            label: 'Very Dissatisfied',
        },
        2: {
            icon: <SentimentSatisfiedIcon color="warning" fontSize={"large"} />,
            label: 'Neutral',
        },
        3: {
            icon: <SentimentVerySatisfiedIcon color="success" fontSize={"large"} />,
            label: 'Very Satisfied',
        },
    };

    IconContainer.propTypes = {
        value: PropTypes.number.isRequired,
    };

    function IconContainer(props) {
        const { value, ...other } = props;
        return <span {...other}>{customIcons[value].icon}</span>;
    }

    const StyledRating = styled(Rating)(({ theme }) => ({
        '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
            color: theme.palette.action.disabled,
        },
    }));

    return (
        <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
            <Stack alignItems="center" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative' }}>
                <Box sx={{ textAlign: 'center' }}>
                    <Typography gutterBottom variant="h6">
                        Share your feedback
                    </Typography>

                    {/* sad face to happy face rating */}
                    <StyledRating
                        name="highlight-selected-only"
                        value={satisfactionRating}
                        onChange={(event, newValue) => {
                            setSatisfactionRating(newValue);
                        }}
                        max={3}
                        IconContainerComponent={IconContainer}
                        getLabelText={(value) => customIcons[value].label}
                        highlightSelectedOnly
                    />

                    <div>
                        <TextField
                            fullWidth
                            multiline
                            rows={4}
                            sx={{ fontSize: '11px' }}
                            value={feedback}
                            onChange={(e) => setFeedback(e.target.value)}
                            placeholder="What can we do better?"
                        />
                        <Button
                            variant="contained"
                            size="medium"
                            fullWidth
                            style={{ marginTop: '8px' }}
                            onClick={handleFeedbackSend}
                        >
                            Send
                        </Button>
                        <Snackbar open={signalAlertMessage}
                                  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                  autoHideDuration={3000}
                                  onClose={handleFeedbackSuccessClose}>
                            <Alert onClose={handleFeedbackSuccessClose} severity={alertSeverity}>
                                {alertMessage}
                            </Alert>
                        </Snackbar>
                    </div>
                </Box>
            </Stack>

            {/* todo below textfield looks cool, that's about it, so i saved the code as comment*/}
            {/*<TextField*/}
            {/*  fullWidth*/}
            {/*  size="small"*/}
            {/*  placeholder="Your email"*/}
            {/*  InputProps={{*/}
            {/*    endAdornment: (*/}
            {/*      <Button variant="contained" size="small">*/}
            {/*        Send*/}
            {/*      </Button>*/}
            {/*    ),*/}
            {/*  }}*/}
            {/*/>*/}
        </Box>
    )
}