import {
    Autocomplete,
    Card,
    Checkbox,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer, TablePagination,
    TableRow, TextField, Tooltip,
    Typography
} from "@mui/material";
import {UserListHead, UserListToolbar, UserMoreMenu} from "./index";
import Scrollbar from "../../../components/Scrollbar";
import {fDate} from "../../../utils/formatTime";
import {fCurrency} from "../../../utils/formatNumber";
import Label from "../../../components/Label";
import {sentenceCase} from "change-case";
import SearchNotFound from "../../../components/SearchNotFound";
import {useEffect, useState} from "react";
import TransactionsService from "../../../services/TransactionsService";
import CategoryRuleModal from "./CategoryRuleModal";

const TABLE_HEAD = [
    {id: 'date_created', label: 'Date Created', alignRight: false},
    {id: 'start_date', label: 'Start Date', alignRight: false},
    {id: 'end_date', label: 'End Date', alignRight: false},
    {id: 'frequency', label: 'Frequency', alignRight: false},
    {id: 'merchant_name', label: 'Payee', alignRight: false},
    {id: 'transaction_name', label: 'Name', alignRight: false},
    {id: 'amount', label: 'Amount', alignRight: false},
    {id: 'category', label: 'Category', alignRight: false},
    {id: ''},  // not sure what to show here yet, maybe next transaction date(s) based on frequency?
];

export default function TransactionRecurringTable({
                                                 setSelected,
                                                 selected,
                                                 selectedTransaction,
                                                 setSelectedTransaction,
                                                 categories,
                                                 setCreateTransactionModalOpen,
                                                 filterName,
                                                 refresh,
                                                 setRefresh
                                             }) {
    const [transactions, setTransactions] = useState([]);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('date_created');
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [page, setPage] = useState(0);
    const [totalTransactions, setTotalTransactions] = useState(0);

    const frequencyMap = {
        "DAILY": "Daily",
        "WEEKLY": "Weekly",
        "BIWEEKLY": "Bi-weekly",
        "MONTHLY_CUSTOM": "Monthly",
        "MONTHLY_LAST_DAY": "Monthly (last day)"
    };

    useEffect(() => {
        setRefresh(true);
    }, [page, rowsPerPage])

    useEffect(() => {
        if (refresh) {
            getTransactions();
        }
        if (filterName.length > 2) {
            searchTransactions(filterName);
        }
    }, [refresh]);

    useEffect(() => {
        if (filterName.length > 2) { // if filter is valid (>= 3 chars) then search for transactions
            searchTransactions(filterName);
        } else { // otherwise if filter is less than 3 chars, just get all transactions
            searchTransactions("");
        }
    }, [filterName]);

    const searchTransactions = (query) => {
        TransactionsService.searchRecurringTransactions(query, orderBy, order, rowsPerPage, page + 1, response => {
            let typed_transactions = [];
            setTotalTransactions(response.data.count);
            response.data.results.forEach(transaction => {
                transaction.date_created = new Date(transaction.date_created);
                transaction.start_date = new Date(transaction.start_date);
                if (transaction.end_date) {
                    transaction.end_date = new Date(transaction.end_date);
                }
                typed_transactions.push(transaction);
            })
            setTransactions(typed_transactions);
            setRefresh(false);
        }, error => {
            console.log(error);
            setRefresh(false);
        })
    }

    const getTransactions = () => {
        // todo: should find a way to call getTransactions only once in the app, and have it be available in all components (optimization?)
        searchTransactions(filterName, orderBy, order, rowsPerPage, page + 1);
    }

    const onEditTransaction = (transaction) => {
        setSelectedTransaction(transaction);
        setCreateTransactionModalOpen(true);
    }

    const onDeleteTransaction = (transaction) => {
        TransactionsService.deleteRecurringTransaction(transaction.id, response => {
            getTransactions();
        }, error => {
            console.log(error);
        })
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        getTransactions();
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            setSelected(transactions);
            return;
        }
        setSelected([]);
    };

    // const emptyRows = page > 0 ? Math.max(0, rowsPerPage - transactions.length) : 0;

    const isTransactionNotFound = transactions.length === 0;

    const updateTransactionCategory = (transaction, category) => {
        if (category === null || category === undefined) {
            // disallow unsetting category
            return;
        }

        TransactionsService.updateRecurringTransaction({
            transaction: {
                id: transaction.id,
                category_id: category.id
            }
        }, response => {
            let updatedTransaction = response.data.transaction;
            updatedTransaction.date_created = new Date(updatedTransaction.date_created);
            updatedTransaction.start_date = new Date(updatedTransaction.start_date);
            if (updatedTransaction.end_date) {
                updatedTransaction.end_date = new Date(updatedTransaction.end_date);
            }

            let transactionsCopy = [...transactions];
            let indexToUpdate;

            for (let transactionIndex in transactionsCopy) {
                if (transactionsCopy[transactionIndex].id === updatedTransaction.id) {
                    indexToUpdate = transactionIndex;
                    break;
                }
            }

            transactionsCopy[indexToUpdate] = updatedTransaction;

            setTransactions(transactionsCopy);
        }, error => {
            console.log(error);
        })
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const handleClick = (event, transaction) => {
        const selectedIndex = selected.indexOf(transaction);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, transaction);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const addOrdinalSuffix = (number) => {
        if (number === 0) return "0"; // handle edge case

        // Exceptions for 11th, 12th, and 13th
        if (number % 100 >= 11 && number % 100 <= 13) {
            return number + "th";
        }

        // For other numbers
        switch (number % 10) {
            case 1:
                return number + "st";
            case 2:
                return number + "nd";
            case 3:
                return number + "rd";
            default:
                return number + "th";
        }
    }

    return (
        <>
            <Scrollbar>
                <TableContainer sx={{minWidth: 800}}>
                    <Table>
                        <UserListHead
                            order={order}
                            orderBy={orderBy}
                            headLabel={TABLE_HEAD}
                            rowCount={transactions.length}
                            numSelected={selected.length}
                            onRequestSort={handleRequestSort}
                            onSelectAllClick={handleSelectAllClick}
                        />
                        <TableBody>
                            {transactions.map((row) => {
                                const {
                                    id,
                                    date_created,
                                    start_date,
                                    end_date,
                                    frequency,
                                    day_of_month,
                                    merchant_name,
                                    transaction_name,
                                    iso_currency_code,
                                    amount,
                                    display_iso_currency_amount,
                                    category,
                                    next_transaction_date
                                } = row;
                                const isItemSelected = selected.indexOf(row) !== -1;

                                return (
                                    <TableRow
                                        hover
                                        key={id}
                                        tabIndex={-1}
                                        role="checkbox"
                                        selected={isItemSelected}
                                        aria-checked={isItemSelected}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox checked={isItemSelected}
                                                      onChange={(event) => handleClick(event, row)}/>
                                        </TableCell>
                                        <TableCell component="th" scope="row" padding="none">
                                            <Stack direction="row" alignItems="center" spacing={2}>
                                                <Typography variant="subtitle2" noWrap>
                                                    {fDate(date_created)}
                                                </Typography>
                                            </Stack>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography variant="subtitle2" noWrap>
                                                {fDate(start_date)}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography variant="subtitle2" noWrap>
                                                {end_date ? fDate(end_date) : ""}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left">{
                                            frequencyMap[frequency] + (day_of_month ? `  (${addOrdinalSuffix(day_of_month)})` : "")
                                        }</TableCell>
                                        <TableCell align="left">{merchant_name}</TableCell>
                                        <TableCell align="left">{transaction_name}</TableCell>
                                        <TableCell align="left">
                                            { amount != display_iso_currency_amount &&
                                                <Tooltip title={fCurrency(amount) + " " + iso_currency_code}>
                                                    <div>{fCurrency(display_iso_currency_amount)}*</div>
                                                </Tooltip>
                                            }
                                            {amount == display_iso_currency_amount &&
                                                <div>{fCurrency(display_iso_currency_amount)}</div>
                                            }
                                        </TableCell>
                                        <TableCell align="left">
                                            <Autocomplete
                                                disablePortal
                                                selectOnFocus
                                                handleHomeEndKeys
                                                autoHighlight
                                                id="transaction-category"
                                                options={categories}
                                                getOptionLabel={(option) => option.display_name}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                sx={{ width: 175 }}
                                                value={category}
                                                onChange={(event, newCategory) => {
                                                    updateTransactionCategory(row, newCategory);
                                                }}
                                                renderInput={(params) => {
                                                    return <TextField {...params}
                                                                      variant="standard"/>;
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell align="left">
                                            { next_transaction_date ?
                                                <Tooltip title="Delete">
                                                    <Label variant="ghost" color={('success')}>
                                                        { sentenceCase('active') }
                                                    </Label>
                                                </Tooltip> : <Tooltip title="Delete">
                                                    <Label variant="ghost" color={('error')}>
                                                        { sentenceCase('inactive') }
                                                    </Label>
                                                </Tooltip> }
                                        </TableCell>

                                        <TableCell align="right">
                                            <UserMoreMenu
                                                onEditTransaction={() => onEditTransaction(row)}
                                                onDeleteTransaction={() => onDeleteTransaction(row)}/>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {/*{emptyRows > 0 && (*/}
                            {/*    <TableRow style={{height: 53 * emptyRows}}>*/}
                            {/*        <TableCell colSpan={6}/>*/}
                            {/*    </TableRow>*/}
                            {/*)}*/}
                        </TableBody>

                        {isTransactionNotFound && (
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center" colSpan={6} sx={{py: 3}}>
                                        <SearchNotFound searchQuery={filterName}
                                                        allRowsAreEmpty={transactions.length === 0}/>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </Scrollbar>

            <TablePagination
                rowsPerPageOptions={[25, 50, 75, 100]}
                component="div"
                count={totalTransactions}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )
}