import {
    Box,
    Button,
    Card,
    Container, Grid,
    IconButton,
    InputAdornment, Paper,
    Stack,
    Tab,
    Tabs,
    TextField,
    Typography
} from "@mui/material";
import Iconify from "../components/Iconify";
import {CategoriesList, CategoriesSort, CategoryCreate} from "../sections/@dashboard/categories";
import Page from "../components/Page";
import {useEffect, useState} from "react";
import {FormProvider, RHFTextField} from "../components/hook-form";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {LoadingButton} from "@mui/lab";
import {useNavigate} from "react-router-dom";
import * as Yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import UserService from "../services/UserService";
import DashboardService from "../services/DashboardService";
import {fShortenNumber} from "../utils/formatNumber";
import AccountSettiongs from "../sections/@dashboard/settings/AccountSettings";
import PasswordSettings from "../sections/@dashboard/settings/PasswordSettings";

function Item(props: { children: ReactNode }) {
    return null;
}

export default function Settings() {
    const [user, setUser] = useState(null);
    const [settingTab, setSettingTab] = useState(0);

    const handleSettingTabChange = (event, newSettingTab) => {
        setSettingTab(newSettingTab);
    };

    const getUser = () => {
        UserService.getCurrentUser(response => {
            setUser(response.data);
        }, error => {
            console.log(error);
        })
    }

    useEffect(() => {
        refresh();
    }, [])

    const refresh = () => {
        getUser();
    }


    return (
        <Page title="Settings">
            <Container maxWidth={"xl"}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Settings
                    </Typography>
                </Stack>

                <Box>
                    <Tabs value={settingTab}
                          variant="scrollable"
                          scrollButtons="auto"
                          onChange={handleSettingTabChange}>
                        <Tab
                            icon={<Iconify icon={"solar:user-id-bold"} width={24} height={24}/>}
                            iconPosition="start"
                            label="General"
                            sx={{ minHeight: 48 }}
                        />
                        <Tab
                            icon={<Iconify icon={"solar:bill-list-bold"} width={24} height={24}/>}
                            iconPosition="start"
                            label="Billing"
                            sx={{ minHeight: 48 }}
                        />
                        <Tab
                            icon={<Iconify icon={"ic:round-vpn-key"} width={24} height={24}/>}
                            iconPosition="start"
                            label="Security"
                            sx={{ minHeight: 48 }}
                        />
                    </Tabs>
                </Box>

                <br/>

                { settingTab === 0 && <>
                    <AccountSettiongs refresh={refresh} user={user}/>
                </>}

                { settingTab === 1 && <>
                    <Stack direction="row" alignItems="center" spacing={2} p={2}>
                        <Paper variant={"outlined"}
                               sx={{ px: 10, py: 5, textAlign: 'center' }}>
                            <Box>
                                <Iconify icon={"clarity:beta-solid"} width={32} height={32}/>
                            </Box>

                            <Typography variant="h6">Beta</Typography>

                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                {"Free"}
                            </Typography>
                        </Paper>
                    </Stack>
                </>}

                { settingTab === 2 && <>
                    <PasswordSettings refresh={refresh} user={user}/>
                </>}

            </Container>
        </Page>
    );
}