import {useRef, useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
// material
import {Menu, MenuItem, IconButton, ListItemIcon, ListItemText} from '@mui/material';
// component
import Iconify from '../../../components/Iconify';
import ConfirmationDialog from "../../../components/ConfirmationDialog";

// ----------------------------------------------------------------------

export default function TransactionMoreMenu({onCreateCategoryRule, onEditTransaction, onDeleteTransaction, ...other}) {
    const ref = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    const [deleteTransactionOpen, setDeleteTransactionOpen] = useState(false);

    function invokeOnCreateCategoryRule() {
        onCreateCategoryRule();
        setIsOpen(false);
    }

    function invokeOnEditTransaction() {
        onEditTransaction();
        setIsOpen(false);
    }

    function invokeOnDeleteTransaction() {
        onDeleteTransaction();
        setIsOpen(false);
    }

    return (
        <>
            <IconButton ref={ref} onClick={() => setIsOpen(true)}>
                <Iconify icon="eva:more-vertical-fill" width={20} height={20}/>
            </IconButton>

            <Menu
                open={isOpen}
                anchorEl={ref.current}
                onClose={() => setIsOpen(false)}
                PaperProps={{
                    sx: {width: 200, maxWidth: '100%'},
                }}
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
            >

                {onCreateCategoryRule && <MenuItem sx={{color: 'text.secondary'}} onClick={invokeOnCreateCategoryRule}>
                    <ListItemIcon>
                        <Iconify icon="carbon:category-new" width={24} height={24}/>
                    </ListItemIcon>
                    <ListItemText primary="Category rule" primaryTypographyProps={{variant: 'body2'}}/>
                </MenuItem>}

                <MenuItem sx={{color: 'text.secondary'}} onClick={invokeOnEditTransaction}>
                    <ListItemIcon>
                        <Iconify icon="bxs:edit" width={24} height={24}/>
                    </ListItemIcon>
                    <ListItemText primary="Edit" primaryTypographyProps={{variant: 'body2'}}/>
                </MenuItem>

                <MenuItem sx={{color: 'text.secondary'}} onClick={() => setDeleteTransactionOpen(true)}>
                    <ListItemIcon>
                        <Iconify icon="eva:trash-2-outline" width={24} height={24}/>
                    </ListItemIcon>
                    <ListItemText primary="Delete" primaryTypographyProps={{variant: 'body2'}}/>
                </MenuItem>
            </Menu>

            <ConfirmationDialog isOpen={deleteTransactionOpen}
                                titleText={"Delete transaction"}
                                dialogText={"Are you sure you want to delete this transaction?"}
                                confirmButtonText={"Delete"}
                                denyButtonText={"Cancel"}
                                onConfirm={invokeOnDeleteTransaction}
                                onDeny={() => setDeleteTransactionOpen(false)}/>
        </>
    );
}
