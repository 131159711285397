import {Autocomplete, Stack, TextField} from "@mui/material";
import {FormProvider, RHFTextField} from "../../../components/hook-form";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {LoadingButton} from "@mui/lab";
import {useEffect, useState} from "react";
import * as Yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import UserService from "../../../services/UserService";

export default function AccountSettiongs({refresh, user}) {
    // const [dateOfBirth, setDateOfBirth] = useState();
    const [displayCurrency, setDisplayCurrency] = useState('CAD');
    const [defaultValues, setDefaultValues] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        // city: '',
        // country: '',
    });

    const AccountSchema = Yup.object().shape({
        firstName: Yup.string().required('First name required'),
        lastName: Yup.string().required('Last name required'),
        email: Yup.string().email('Email must be a valid email address').required('Email is required'),
        // city: Yup.string().optional(),
        // country: Yup.string().optional(),
    });

    const methods = useForm({
        resolver: yupResolver(AccountSchema),
        defaultValues,
    });

    useEffect(() => {
        if (user) {
            setDefaultValues({
                firstName: user.first_name,
                lastName: user.last_name,
                email: user.email,
                // city: user.city,
                // country: user.country,
            });
            // setDateOfBirth(dayjs(user.date_of_birth));
            setDisplayCurrency(user.display_iso_currency_code);
        }
    }, [user])

    useEffect(() => {
        methods.reset(defaultValues);
    }, [defaultValues]);

    const {
        handleSubmit,
        formState: {isSubmitting},
    } = methods;

    const onSubmit = async (data) => {
        UserService.updateUser({
            first_name: data.firstName,
            last_name: data.lastName,
            email: data.email,
            // city: data.city,
            // country: data.country,
            // date_of_birth: [dateOfBirth.$y, dateOfBirth.$M + 1, dateOfBirth.$D].join("-"),
            display_iso_currency_code: displayCurrency,
        }, response => {
            refresh();
        }, error => {
            console.log(error)
        })
    };

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
                <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                    <RHFTextField name="firstName" label="First name"/>
                    <RHFTextField name="lastName" label="Last name"/>
                </Stack>

                <RHFTextField name="email" label="Email address"/>

                {/*<LocalizationProvider dateAdapter={AdapterDayjs}>*/}
                {/*    <DatePicker*/}
                {/*        name="dateOfBirth"*/}
                {/*        views={['year', 'month']}*/}
                {/*        label="Date of Birth"*/}
                {/*        minDate={dayjs('1900-01-01')}*/}
                {/*        value={dateOfBirth}*/}
                {/*        onChange={(newDate) => setDateOfBirth(newDate)}*/}
                {/*        renderInput={(params) => <TextField name="dateOfBirth" {...params} helperText={null}/>}*/}
                {/*    />*/}
                {/*</LocalizationProvider>*/}

                {/*<Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>*/}
                {/*    <RHFTextField name="city" label="City"/>*/}
                {/*    <RHFTextField name="country" label="Country"/>*/}
                {/*</Stack>*/}

                <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                    <Autocomplete
                        disablePortal
                        selectOnFocus
                        handleHomeEndKeys
                        autoHighlight
                        fullWidth={true}
                        id="display-currency"
                        options={["USD", "CAD"]}
                        getOptionLabel={(option) => option}
                        isOptionEqualToValue={(option, value) => option === value}
                        value={displayCurrency}
                        onChange={(event, newCategory) => {
                            setDisplayCurrency(newCategory);
                        }}
                        renderInput={(params) => {
                            return <TextField {...params} />;
                        }}
                    />
                </Stack>

                <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
                    Update
                </LoadingButton>
            </Stack>
        </FormProvider>
    )
}