import {useState, Fragment, useEffect} from 'react';
// material
import {
    Paper,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    ListItem,
    TextField,
    Divider,
    InputAdornment,
    FormGroup, Autocomplete, Checkbox, FormControlLabel,
} from '@mui/material';
import TransactionsService from "../../../services/TransactionsService";
import dayjs from "dayjs";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import auth from "../../../utils/auth";
// components

// ----------------------------------------------------------------------

export default function TransactionCreate({existingTransaction, createTransactionModalOpen, setCreateTransactionModalOpen, actionOnClose, categories, ...other}) {
    const [transactionName, setTransactionName] = useState();
    const [category, setCategory] = useState();
    const [amount, setAmount] = useState();
    const [isoCurrencyCode, setIsoCurrencyCode] = useState("CAD");
    const [transactionDateDayjs, setTransactionDateDayjs] = useState(dayjs());
    const [endDateDayjs, setEndDateDayjs] = useState(null);
    const [merchantName, setMerchantName] = useState();
    const [dayOfMonth, setDayOfMonth] = useState();
    const [frequencyOptions, setFrequencyOptions] = useState([
        {id: "DAILY", display_name: "Daily"},
        {id: "WEEKLY", display_name: "Weekly"},
        {id: "BIWEEKLY", display_name: "Bi-weekly"},
        {id: "MONTHLY_CUSTOM", display_name: "Monthly (select day)"},
        {id: "MONTHLY_LAST_DAY", display_name: "Monthly (last day)"},
    ])

    const [isRecurring, setIsRecurring] = useState();
    const [frequency, setFrequency] = useState();

    const handleTransactionNameChange = (event) => {
        setTransactionName(event.target.value);
    }

    const handleAmountChange = (event) => {
        setAmount(event.target.value);
    }

    const handleMerchantNameChange = (event) => {
        setMerchantName(event.target.value);
    }

    useEffect(() => {
        if (existingTransaction) {
            setTransactionName(existingTransaction.transaction_name);
            setCategory(existingTransaction.category);
            setAmount(existingTransaction.amount);
            setTransactionDateDayjs(dayjs(existingTransaction.transaction_date));
            setIsRecurring(existingTransaction.start_date != null);
            setMerchantName(existingTransaction.start_date ? existingTransaction.merchant_name : existingTransaction.plaid_merchant_name);
            setFrequency(existingTransaction.frequency ? frequencyOptions.find(frequency => frequency.id === existingTransaction.frequency) : null);
            setEndDateDayjs(existingTransaction.end_date ? dayjs(existingTransaction.end_date) : null);
            setDayOfMonth(existingTransaction.day_of_month);
        } else {
            setTransactionName(null);
            setCategory(null);
            setAmount(null);
            setMerchantName(null);
            setTransactionDateDayjs(dayjs());
            setIsRecurring(null);
            setFrequency(null);
            setEndDateDayjs(null);
            setDayOfMonth(null);
            let userData = auth.getUserData();
            if (userData) {
                setIsoCurrencyCode(userData.display_iso_currency_code);
            }
        }
    }, [existingTransaction, createTransactionModalOpen]);

    const handleModalClose = (changeMade) => {
        setCreateTransactionModalOpen(false);
        setTransactionName(null);
        setCategory(null);
        setAmount(null);
        setMerchantName(null);
        setTransactionDateDayjs(dayjs());
        setIsRecurring(null);
        setFrequency(null);
        setEndDateDayjs(null);
        setDayOfMonth(null);
        if (changeMade) {
            actionOnClose(true);
        }
    }

    const handleCreate = () => {
        const transaction = {
            transaction_name: transactionName,
            category_id: category ? category.id : null,
            amount: amount,
            iso_currency_code: isoCurrencyCode,
            transaction_date: transactionDateDayjs.toISOString(),  // start date if recurring
            merchant_name: merchantName,
            recurring: isRecurring,
            frequency: frequency ? frequency.id : null,
            end_date: endDateDayjs ? endDateDayjs.toISOString() : null,
            day_of_month: dayOfMonth
        }
        if (isRecurring) {
            TransactionsService.createRecurringTransaction({
                transaction: transaction
            }, response => {
                handleModalClose(true);
            }, error => {
                console.log(error);
            });
        } else {
            TransactionsService.createTransaction({
                transaction: transaction
            }, response => {
                handleModalClose(true);
            }, error => {
                console.log(error);
            });
        }
    }

    const handleUpdate = () => {
        const transaction = {
            id: existingTransaction.id,
            transaction_name: transactionName,
            category_id: category ? category.id : null,
            amount: amount,
            iso_currency_code: isoCurrencyCode,
            transaction_date: transactionDateDayjs.toISOString(),  // start date if recurring
            merchant_name: merchantName,
            recurring: isRecurring,
            frequency: frequency ? frequency.id : null,
            end_date: endDateDayjs ? endDateDayjs.toISOString() : null,
            day_of_month: dayOfMonth
        }
        if (isRecurring) {
            TransactionsService.updateRecurringTransaction({
                transaction: transaction
            }, response => {
                handleModalClose(true);
            }, error => {
                console.log(error);
            });
        } else {
            TransactionsService.updateTransaction({
                transaction: transaction
            }, response => {
                handleModalClose(true);
            }, error => {
                console.log(error);
            });
        }
    }

    return (
        <>
            <Dialog
                open={createTransactionModalOpen}
                onClose={handleModalClose}>
                <DialogTitle>
                    { existingTransaction ? "Edit Transaction" : "Create Transaction" }
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        { existingTransaction ? "Edit the transaction details below." : "Enter the transaction details below." }
                    </DialogContentText>
                    <TextField // Transaction name
                        autoFocus
                        margin="dense"
                        label="Transaction Name"
                        variant={"standard"}
                        fullWidth
                        value={transactionName}
                        onChange={handleTransactionNameChange}
                    />
                    <br/>
                    <Autocomplete // Category selector
                        disablePortal
                        selectOnFocus
                        handleHomeEndKeys
                        autoHighlight
                        fullWidth
                        id="new-transaction-category"
                        options={categories}
                        getOptionLabel={(option) => option.display_name}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        value={category}
                        onChange={(event, newCategory) => setCategory(newCategory)}
                        renderInput={(params) => {
                            return <TextField {...params}
                                label={"Category"}
                                              variant="standard"/>;
                        }}
                    />
                    <Autocomplete
                        disablePortal
                        selectOnFocus
                        handleHomeEndKeys
                        autoHighlight
                        fullWidth={true}
                        id="display-currency"
                        options={["USD", "CAD"]}
                        getOptionLabel={(option) => option}
                        isOptionEqualToValue={(option, value) => option === value}
                        value={isoCurrencyCode}
                        onChange={(event, newCategory) => {
                            setIsoCurrencyCode(newCategory);
                        }}
                        renderInput={(params) => {
                            return <TextField {...params}
                                              margin="dense"
                                              label={"Currency"}
                                              variant="standard"/>;
                        }}
                    />
                    <TextField // Amount
                        margin="dense"
                        label="Amount"
                        fullWidth
                        value={amount}
                        variant={"standard"}
                        onChange={handleAmountChange}
                        type={"number"}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                    />
                    <br/>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker // Transaction date
                            name="transactionDate"
                            minDate={dayjs('1900-01-01')}
                            value={transactionDateDayjs}
                            label={isRecurring ? "Start Date" : "Transaction Date"}
                            onChange={(newDate) => setTransactionDateDayjs(newDate)}
                            renderInput={(params) => {
                                return <TextField
                                    margin="dense"
                                    label="Transaction Date"
                                    fullWidth
                                    {...params}
                                    variant={"standard"}
                                />
                            }}
                        />
                    </LocalizationProvider>
                    <br/>
                    {/*{ isRecurring && <Alert severity="info">This transaction is recurring.</Alert>}*/}
                    { isRecurring && <>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker // End date
                                name="endDate"
                                minDate={dayjs('1900-01-01')}
                                value={endDateDayjs}
                                label={"End date"}
                                onChange={(newDate) => setEndDateDayjs(newDate)}
                                renderInput={(params) => {
                                    return <TextField
                                        margin="dense"
                                        label="End Date"
                                        fullWidth
                                        {...params}
                                        variant={"standard"}
                                    />
                                }}
                            />
                        </LocalizationProvider>
                        <br/>
                    </>}
                    <TextField // Merchant name
                        margin="dense"
                        label="Merchant Name"
                        fullWidth
                        variant={"standard"}
                        value={merchantName}
                        onChange={handleMerchantNameChange}
                    />
                    <br/>

                    { !existingTransaction && <FormControlLabel control={
                        <Checkbox
                            value={isRecurring}
                            checked={isRecurring}
                            onChange={(event) => setIsRecurring(event.target.checked)}
                        />
                    } label="Recurring" />}

                    { isRecurring && <>
                        <br/>

                        <Autocomplete // Frequency selector
                            disablePortal
                            selectOnFocus
                            handleHomeEndKeys
                            autoHighlight
                            fullWidth
                            id="new-transaction-frequency"
                            options={frequencyOptions}
                            getOptionLabel={(option) => option.display_name}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            value={frequency}
                            onChange={(event, newFrequency) => setFrequency(newFrequency)}
                            renderInput={(params) => {
                                return <TextField {...params}
                                                  label={"Frequency"}
                                                  variant="standard"/>;
                            }}
                        />

                        { frequency && frequency.id === "MONTHLY_CUSTOM" && <>
                            <Autocomplete // Day of month selector
                                disablePortal
                                selectOnFocus
                                handleHomeEndKeys
                                autoHighlight
                                fullWidth
                                id="day-of-month-selector"
                                options={Array.from({length: 31}, (_, i) => i + 1).map((day) => day.toString())}
                                getOptionLabel={(option) => option}
                                isOptionEqualToValue={(option, value) => option === value}
                                value={dayOfMonth}
                                onChange={(event, newDayOfMonth) => setDayOfMonth(newDayOfMonth)}
                                renderInput={(params) => {
                                    return <TextField {...params}
                                                      label={"Day of Month"}
                                                      variant="standard"/>;
                                }}
                            />
                        </>}


                    </>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleModalClose}>Cancel</Button>
                    { existingTransaction ?
                        <Button onClick={handleUpdate}>Update</Button>
                        :
                        <Button onClick={handleCreate}>Create</Button>
                    }
                </DialogActions>
            </Dialog>
        </>
    );
}
