import ApiService from "./ApiService";
import AuthService from "./AuthService";

export default {
    createTransaction(transaction, success, failure) {
        ApiService.post(`/create_manual_transaction`, transaction, AuthService.getHeaders()).then(success).catch(failure);
    },
    createRecurringTransaction(transaction, success, failure) {
        ApiService.post(`/create_recurring_transaction`, transaction, AuthService.getHeaders()).then(success).catch(failure);
    },
    searchTransactions(query, orderBy, order, rowsPerPage, page, success, failure) {
        ApiService.get(`/search_transactions?query=${query}&order_by=${orderBy}&order=${order}&page_size=${rowsPerPage}&page=${page}`, AuthService.getHeaders()).then(success).catch(failure);
    },
    searchRecurringTransactions(query, orderBy, order, rowsPerPage, page, success, failure) {
        ApiService.get(`/search_recurring_transactions?query=${query}&order_by=${orderBy}&order=${order}&page_size=${rowsPerPage}&page=${page}`, AuthService.getHeaders()).then(success).catch(failure);
    },
    updateTransaction(data, success, failure) {
        ApiService.post('/update_transaction', data, AuthService.getHeaders()).then(success).catch(failure);
    },
    updateRecurringTransaction(data, success, failure) {
        ApiService.post('/update_recurring_transaction', data, AuthService.getHeaders()).then(success).catch(failure);
    },
    deleteTransaction(id, success, failure) {
        ApiService.post(`/delete_transaction`, {
            'transaction_id': id,
        }, AuthService.getHeaders()).then(success).catch(failure);
    },
    deleteRecurringTransaction(id, success, failure) {
        ApiService.post(`/delete_recurring_transaction`, {
            'transaction_id': id,
        }, AuthService.getHeaders()).then(success).catch(failure);
    }
}
