import PropTypes from 'prop-types';
// material
import {
    Checkbox, Collapse, Divider,
    FormControlLabel, FormGroup,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader, Tooltip
} from '@mui/material';
import Iconify from "../../../components/Iconify";
import CategoryService from "../../../services/CategoryService";
import CategoryRuleModal from "../transactions/CategoryRuleModal";
import {useState} from "react";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import Button from "@mui/material/Button";
import {fCurrency} from "../../../utils/formatNumber";
import AssetService from "../../../services/AssetService";
import AssetCreateUpdate from "./AssetCreateUpdate";

// ----------------------------------------------------------------------

AssetsList.propTypes = {
    assets: PropTypes.array.isRequired
};

export default function AssetsList({assets, refreshPage, ...other}) {
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [deleteAssetConfirmationOpen, setDeleteAssetConfirmationOpen] = useState(false);
    const [selectedAsset, setSelectedAsset] = useState(null);

    const handleAssetDelete = (asset) => {
        AssetService.deleteAsset(asset.id, response => {
            setDeleteAssetConfirmationOpen(false);
            setSelectedAsset(null);
            refreshPage();
        }, error => {
            console.log(error);
        });
    }

    const selectAssetAndShowUpdateModal = (asset) => {
        setSelectedAsset(asset);
        setEditModalOpen(true);
    }

    const selectAssetAndShowDeleteConfirmation = (asset) => {
        setSelectedAsset(asset);
        setDeleteAssetConfirmationOpen(true);
    }

    return (
        <>
            <List
                sx={{width: '100%', bgcolor: 'background.paper'}}
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
                {assets.map((asset, assetIdx) => (
                    <>
                        <ListItem key={"asset" + assetIdx}>
                            <ListItemText primary={asset.name}
                                          secondary={fCurrency(asset.value)}/>

                            <ListItemIcon>
                                <Button onClick={() => selectAssetAndShowUpdateModal(asset)}
                                >
                                    Edit
                                </Button>
                            </ListItemIcon>
                            &nbsp;
                            <ListItemIcon>
                                <Button onClick={() => selectAssetAndShowDeleteConfirmation(asset)}
                                        color={"error"}
                                >
                                    Delete
                                </Button>
                            </ListItemIcon>

                        </ListItem>
                        { assetIdx < assets.length - 1 && <Divider />}
                    </>
                ))}

                {
                    assets.length === 0 && <>
                        <ListItem key={"asset0"}>

                            <ListItemText primary={"You have no current assets."}
                                          secondary={"Any assets you add here will get reflected in your net worth."}/>

                        </ListItem>
                    </>
                }
            </List>
            <ConfirmationDialog isOpen={deleteAssetConfirmationOpen}
                                titleText={"Delete asset"}
                                dialogText={"Are you sure you want to delete this asset?"}
                                confirmButtonText={"Delete"}
                                denyButtonText={"Cancel"}
                                onConfirm={() => handleAssetDelete(selectedAsset)}
                                onDeny={() => setDeleteAssetConfirmationOpen(false)}/>
            <AssetCreateUpdate createEditModalOpen={editModalOpen}
                               setCreateEditModalOpen={setEditModalOpen}
                               existingAsset={selectedAsset}
                               refetchAssets={refreshPage}/>
        </>
    );
}
