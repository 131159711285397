import ApiService from "./ApiService";
import AuthService from "./AuthService";
import auth from "../utils/auth";

export default {
    getCurrentUser(success, failure) {
        ApiService.get('/users/me', AuthService.getHeaders()).then(function (response) {
            auth.setUserData(response.data);
            success(response);
        }).catch(failure);
    },
    signUp(user, success, failure) {
        ApiService.post('/users/', user).then(success).catch(failure);
    },
    updateUser(user, success, failure) {
        ApiService.put('/users/me/', user, AuthService.getHeaders()).then(success).catch(failure);
    },
    setPassword(password, success, failure) {
        ApiService.post('/users/set_password/', password, AuthService.getHeaders()).then(success).catch(failure);
    },
    resetPassword(data, success, failure) {
        ApiService.post('/users/reset_password/', data).then(success).catch(failure);
    },
    resetPasswordConfirm(data, success, failure) {
        ApiService.post('/users/reset_password_confirm/', data).then(success).catch(failure);
    },
}