import {useEffect, useState} from 'react';
// material
import {
    Card,
    Stack,
    Button,
    Container,
    Typography,
} from '@mui/material';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import {UserListToolbar} from '../sections/@dashboard/transactions';
// utils
import CategoryService from "../services/CategoryService";
import TransactionCreate from "../sections/@dashboard/transactions/TransactionCreate";
import TransactionMainTable from "../sections/@dashboard/transactions/TransactionMainTable";
import TransactionRecurringTable from "../sections/@dashboard/transactions/TransactionRecurringTable";

// ----------------------------------------------------------------------
export default function Transactions() {
    const [refresh, setRefresh] = useState(false);
    const [categories, setCategories] = useState([]);
    const [filterName, setFilterName] = useState('');
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [createTransactionModalOpen, setCreateTransactionModalOpen] = useState(false);
    const [toggledTransaction, setToggledTransaction] = useState('transactions'); // one of 'transactions' or 'recurring'
    const [selected, setSelected] = useState([]);

    const setToggledTransactionWrapper = (event, selection) => {
        setToggledTransaction(selection);
    };

    const handleCreateTransactionModalClose = (changeMade = false) => {
        setSelectedTransaction(null);
        if (changeMade) {
            setRefresh(true);
        }
    }

    const getCategories = () => {
        CategoryService.getCategories(response => {
            setCategories(response.data.categories.filter(category => !category.date_deleted));
        }, error => {
            console.log(error);
        })
    }

    useEffect(() => {
        getCategories();
    }, []);

    const handleFilterByName = (event) => {
        let filteredName = event.target.value;
        setFilterName(filteredName);
    };

    return (
        <Page title="Transactions">
            <Container maxWidth={"xl"}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Transactions
                    </Typography>
                    <Button variant="contained"
                            onClick={() => setCreateTransactionModalOpen(true)}
                            startIcon={<Iconify icon="eva:plus-fill"/>}>
                        New Transaction
                    </Button>
                    <TransactionCreate actionOnClose={handleCreateTransactionModalClose}
                                       existingTransaction={selectedTransaction}
                                       createTransactionModalOpen={createTransactionModalOpen}
                                       setCreateTransactionModalOpen={setCreateTransactionModalOpen}
                                       categories={categories}/>
                </Stack>

                <Card>
                    <UserListToolbar numSelected={selected.length}
                                     selectedAmountTotal={selected.reduce((total, txn) => total + txn.amount, 0)}
                                     filterName={filterName}
                                     onFilterName={handleFilterByName}
                                     toggledTransaction={toggledTransaction}
                                     setToggledTransaction={setToggledTransactionWrapper}
                    />

                    { toggledTransaction === 'transactions' ? (
                        <TransactionMainTable selected={selected}
                                              setSelected={setSelected}
                                              selectedTransaction={selectedTransaction}
                                              setSelectedTransaction={setSelectedTransaction}
                                              filterName={filterName}
                                              categories={categories}
                                              setCreateTransactionModalOpen={setCreateTransactionModalOpen}
                                              refresh={refresh}
                                              setRefresh={setRefresh}
                        />
                    ) : (
                        <TransactionRecurringTable selected={selected}
                                                   setSelected={setSelected}
                                                   selectedTransaction={selectedTransaction}
                                                   setSelectedTransaction={setSelectedTransaction}
                                                   filterName={filterName}
                                                   categories={categories}
                                                   setCreateTransactionModalOpen={setCreateTransactionModalOpen}
                                                   refresh={refresh}
                                                   setRefresh={setRefresh}
                        />
                    )}
                </Card>
            </Container>
        </Page>
    );
}
