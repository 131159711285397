import {Link as RouterLink, useNavigate} from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import {Card, Link, Container, Typography, Stack, InputAdornment, IconButton, Box} from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
import Logo from '../components/Logo';
// sections
import { LoginForm } from '../sections/auth/login';
import AuthSocial from '../sections/auth/AuthSocial';
import {FormProvider, RHFCheckbox, RHFTextField} from "../components/hook-form";
import Iconify from "../components/Iconify";
import {LoadingButton} from "@mui/lab";
import * as Yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import AuthService from "../services/AuthService";
import auth from "../utils/auth";
import UserService from "../services/UserService";

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
    top: 0,
    zIndex: 9,
    lineHeight: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    padding: theme.spacing(3),
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
        alignItems: 'flex-start',
        padding: theme.spacing(7, 5, 0, 7),
    },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    maxWidth: 464,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function ForgotPassword() {
    const navigate = useNavigate();

    const mdUp = useResponsive('up', 'md');

    const ForgotPasswordSchema = Yup.object().shape({
        email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    });

    const defaultValues = {
        email: '',
    };

    const methods = useForm({
        resolver: yupResolver(ForgotPasswordSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        formState: {isSubmitting},
    } = methods;

    const onSubmit = async (data) => {
        UserService.resetPassword({
            email: data.email,
        }, response => {
            navigate('/login', { replace: true });
        }, error => {
            console.log(error);
        })
    };

    return (
        <Page title="Forgot Password">
            <RootStyle>
                <HeaderStyle>
                    <Logo />
                </HeaderStyle>

                {/* todo disable the left sidebar on login and register page for now */}
                {false && mdUp && (
                    <SectionStyle>
                        <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
                            Hi, Welcome Back
                        </Typography>
                        <img src="/static/illustrations/illustration_login.png" alt="login" />
                    </SectionStyle>
                )}

                <Container maxWidth="sm">
                    <ContentStyle>
                        <Typography variant="h4" gutterBottom>
                            Forgot your password?
                        </Typography>

                        <Typography sx={{ color: 'text.secondary', mb: 5 }}>
                            Enter the email address associated with your account, and we'll email you a link to reset
                            your password.
                        </Typography>

                        {/* todo enable when google auth works */}
                        {/*<AuthSocial />*/}

                        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                            <Stack spacing={3}>
                                <RHFTextField name="email" label="Email address"/>
                            </Stack>

                            <br/>
                            <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
                                Send request
                            </LoadingButton>
                            <Link
                                component={RouterLink}
                                variant="subtitle2"
                                to="/login"
                                sx={{ mt: 1, display: 'block', textAlign: 'center' }}>
                                Return to sign in
                            </Link>
                        </FormProvider>
                    </ContentStyle>
                </Container>
            </RootStyle>
        </Page>
    );
}
