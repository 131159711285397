import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import {
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  Stack,
  ButtonGroup, Button
} from '@mui/material';
// component
import Iconify from '../../../components/Iconify';
import {fCurrency} from "../../../utils/formatNumber";
import {ToggleButton, ToggleButtonGroup} from "@mui/lab";

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

TransactionListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
};

export default function TransactionListToolbar({ numSelected, selectedAmountTotal, filterName, onFilterName, toggledTransaction, setToggledTransaction }) {
  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      {numSelected > 0 ? (
        <Stack direction="row" spacing={2}>
          <Typography component="div" variant="subtitle1">
            {numSelected} selected
          </Typography>
          <Typography>
            {fCurrency(selectedAmountTotal)} total
          </Typography>
        </Stack>
      ) : (
        <>
        <Stack direction="row" spacing={2}>
          <Stack direction="row" spacing={2}>
            <ToggleButtonGroup
                color="primary"
                value={toggledTransaction}
                exclusive
                onChange={setToggledTransaction}
                aria-label="Platform"
            >
              <ToggleButton value="transactions">Transactions</ToggleButton>
              <ToggleButton value="recurring">Recurring</ToggleButton>
            </ToggleButtonGroup>
          </Stack>
          <SearchStyle
              value={filterName}
              onChange={onFilterName}
              placeholder="Search transactions..."
              startAdornment={
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              }
          />
        </Stack>
        </>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <Iconify icon="eva:trash-2-fill" />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <Iconify icon="ic:round-filter-list" />
          </IconButton>
        </Tooltip>
      )}
    </RootStyle>
  );
}
