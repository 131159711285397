import ApiService from "./ApiService";
import AuthService from "./AuthService";

export default {
    getAssets(success, failure) {
        ApiService.get('/get_assets', AuthService.getHeaders()).then(success).catch(failure);
    },
    createAsset(data, success, failure) {
        ApiService.post('/create_asset', data, AuthService.getHeaders()).then(success).catch(failure);
    },
    updateAsset(data, success, failure) {
        ApiService.put('/update_asset', data, AuthService.getHeaders()).then(success).catch(failure);
    },
    deleteAsset(id, success, failure) {
        ApiService.post(`/delete_asset`, {
            'asset_id': id,
        }, AuthService.getHeaders()).then(success).catch(failure);
    }
}
