import {useState, Fragment, useEffect} from 'react';
// material
import {
    Paper,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    ListItem,
    TextField,
    Divider,
    InputAdornment,
    Autocomplete,
} from '@mui/material';
import Iconify from "../../../components/Iconify";
import {Alert} from "@mui/lab";
import {FormProvider, RHFTextField} from "../../../components/hook-form";
import {useForm} from "react-hook-form";
import BudgetsService from "../../../services/BudgetsService";
// components

// ----------------------------------------------------------------------

export default function BudgetCreateUpdate({categories, existingBudget, createEditModalOpen, setCreateEditModalOpen, refetchBudgets, ...other}) {
    const [budgetCategory, setBudgetCategory] = useState();
    const [budgetAmount, setBudgetAmount] = useState();

    useEffect(() => {
        if (existingBudget) {
            setBudgetCategory(existingBudget.category_details);
            setBudgetAmount(existingBudget.limit);
        } else {
            setBudgetCategory(null);
            setBudgetAmount('');
        }
    }, [existingBudget, createEditModalOpen]);

    const handleModalClose = (changeMade = false) => {
        setCreateEditModalOpen(false);
        if (changeMade) {
            refetchBudgets();
        }
    }

    const handleBudgetValueChange = (event) => {
        setBudgetAmount(event.target.value);
    }

    const handleCreate = () => {
        BudgetsService.createBudget({
            category_id: budgetCategory.id,
            limit: budgetAmount
        }, response => {
            handleModalClose(true);
        }, error => {
            console.log(error);
        });
    }

    const handleUpdate = () => {
        BudgetsService.updateBudget(existingBudget.id, {
            category_id: budgetCategory.id,
            limit: budgetAmount
        }, response => {
            handleModalClose(true);
        }, error => {
            console.log(error);
        });
    }

    return (
        <>
            <Dialog
                open={createEditModalOpen}
                onClose={handleModalClose}>
                <DialogTitle>
                    { existingBudget ? "Edit budget" : "Create budget"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        { !existingBudget && "Create a new monthly budget." }
                    </DialogContentText>

                    {categories && <Autocomplete
                        disablePortal
                        selectOnFocus
                        handleHomeEndKeys
                        autoHighlight
                        id="budget-category"
                        options={categories}
                        getOptionLabel={(option) => option.display_name}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        value={budgetCategory}
                        onChange={(event, newCategory) => {
                            setBudgetCategory(newCategory);
                        }}
                        renderInput={(params) => {
                            return <TextField {...params}
                                              label={"Category"}
                                              variant="standard"/>;
                        }}
                    />}
                    <br/>
                    <TextField
                        fullWidth
                        margin="dense"
                        id="budget_value"
                        label="Budget Limit (Monthly)"
                        type="number"
                        variant="standard"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        value={budgetAmount}
                        onChange={handleBudgetValueChange}
                    />
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleModalClose}>Cancel</Button>
                    {
                        existingBudget ?
                            <Button onClick={handleUpdate}>Update</Button> :
                            <Button onClick={handleCreate}>Create</Button>
                    }
                </DialogActions>
            </Dialog>
        </>
    );
}
