import {Navigate, useRoutes} from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Blog from './pages/Blog';
import Transactions from './pages/Transactions';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import Categories from './pages/Categories';
import DashboardApp from './pages/DashboardApp';
import LinkInstitution from './pages/LinkInstitution';
import auth from './utils/auth';
import Assets from "./pages/Assets";
import Settings from "./pages/Settings";
import Budgets from "./pages/Budgets";
import ForgotPassword from "./pages/ForgotPassword";
import PasswordResetConfirm from "./pages/PasswordResetConfirm";

// ----------------------------------------------------------------------

export default function Router() {
    const isLoggedIn = auth.getAuthToken() !== undefined && auth.getAuthToken() !== null;

    return useRoutes([
        {
            path: '/dashboard',
            element: isLoggedIn ? <DashboardLayout/> : <Navigate to="/login"/>,
            children: [
                {path: 'app', element: <DashboardApp/>},
                {path: 'transactions', element: <Transactions/>},
                {path: 'categories', element: <Categories/>},
                {path: 'assets', element: <Assets/>},
                {path: 'blog', element: <Blog/>},
                {path: 'link', element: <LinkInstitution/>},
                {path: 'settings', element: <Settings/>},
                {path: 'budgets', element: <Budgets/>},
            ],
        },
        {
            path: 'login',
            element: isLoggedIn ? <Navigate to="/dashboard/app"/> : <Login/>,
        },
        {
            path: 'register',
            element: isLoggedIn ? <Navigate to="/dashboard/app"/> : <Register/>,
        },
        {
            path: 'forgot-password',
            element: isLoggedIn ? <Navigate to="/dashboard/app"/> : <ForgotPassword/>,
        },
        {
            path: 'password/reset/confirm/:uid/:token',
            element: <PasswordResetConfirm/>,
        },
        {
            path: '/',
            element: <LogoOnlyLayout/>,
            children: [
                {path: '/', element: isLoggedIn ? <Navigate to="/dashboard/app"/> : <Navigate to="login"/>},
                {path: '404', element: <NotFound/>},
                {path: '*', element: <Navigate to="/404"/>},
            ],
        },
        {
            path: '*',
            element: <Navigate to="/404" replace/>,
        },
    ]);
}
