// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22}/>;

const navConfig = [
    {
        title: 'dashboard',
        path: '/dashboard/app',
        icon: getIcon('material-symbols:finance-mode'),
    },
    {
        title: 'transactions',
        path: '/dashboard/transactions',
        icon: getIcon('fa6-solid:money-bill-transfer'),
    },
    {
        title: 'categories',
        path: '/dashboard/categories',
        icon: getIcon('material-symbols:category-rounded'),
    },
    {
        title: 'budgets',
        path: '/dashboard/budgets',
        icon: getIcon('eva:pie-chart-2-fill'),
    },
    {
        title: 'assets',
        path: '/dashboard/assets',
        icon: getIcon('material-symbols:add-home-work-rounded'),
    },
    // {
    //     title: 'blog',
    //     path: '/dashboard/blog',
    //     icon: getIcon('eva:file-text-fill'),
    // },
    // {
    //     title: 'login',
    //     path: '/login',
    //     icon: getIcon('eva:lock-fill'),
    // },
    // {
    //     title: 'register',
    //     path: '/register',
    //     icon: getIcon('eva:person-add-fill'),
    // },
    // {
    //     title: 'Not found',
    //     path: '/404',
    //     icon: getIcon('eva:alert-triangle-fill'),
    // },
    {
        title: 'Accounts',
        path: '/dashboard/link',
        icon: getIcon('bxs:bank')
    },
    {
        title: 'Settings',
        path: '/dashboard/settings',
        icon: getIcon('uil:setting')
    }
];

export default navConfig;
