import PropTypes from 'prop-types';
import {Link as RouterLink} from 'react-router-dom';
// material
import {Box, Card, Link, Typography, Stack, Tooltip, IconButton} from '@mui/material';
import {styled} from '@mui/material/styles';
// utils
import {fCurrency} from '../../../utils/formatNumber';
// components
import Label from '../../../components/Label';
import {ColorPreview} from '../../../components/color-utils';
import Iconify from "../../../components/Iconify";

// ----------------------------------------------------------------------

const CategoryPhotoStyled = styled('img')({
    top: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',
});

// ----------------------------------------------------------------------

CategoryCard.propTypes = {
    product: PropTypes.object,
};

export default function CategoryCard({category}) {
    const {id, date_created, display_name, internal_name, modifier} = category;
    const cover = `/static/mock-images/products/${internal_name}.jpeg`;

    return (
        <Card>
            <Box sx={{pt: '100%', position: 'relative'}}>
                {modifier && (
                    <Label
                        variant="filled"
                        color={(modifier === 'sale' && 'error') || 'info'}
                        sx={{
                            zIndex: 9,
                            top: 16,
                            right: 16,
                            position: 'absolute',
                            textTransform: 'uppercase',
                        }}
                    >
                        {modifier}
                    </Label>
                )}

                <CategoryPhotoStyled alt={display_name} src={cover}/>
            </Box>

            <Stack spacing={2} sx={{pl: 1, py: 1}}>
                <Link to="#" color="inherit" underline="hover" component={RouterLink}>
                    <Typography align="center" variant="subtitle2" noWrap>
                        {display_name}
                    </Typography>
                </Link>

                {/*<Stack direction="row" alignItems="center" justifyContent="space-between">*/}
                {/*  <ColorPreview colors={[]} />*/}
                {/*  <Typography variant="subtitle1">*/}
                {/*    {fCurrency(avgSpend)}*/}
                {/*  </Typography>*/}
                {/*</Stack>*/}
            </Stack>
        </Card>
    );
}
