import {Stack, TextField} from "@mui/material";
import {FormProvider, RHFTextField} from "../../../components/hook-form";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {LoadingButton} from "@mui/lab";
import {useEffect, useState} from "react";
import * as Yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import UserService from "../../../services/UserService";

export default function PasswordSettings({refresh, user}) {
    const PasswordSchema = Yup.object().shape({
        oldPassword: Yup.string().required('Old password required'),
        newPassword: Yup.string().required('New password required'),
        newPasswordAgain: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Passwords must match').required('Password confirmation required'),
    });
    const [errorMessage, setErrorMessage] = useState(null);

    const passwordMethods = useForm({
        resolver: yupResolver(PasswordSchema),
        defaultValues: {
            oldPassword: '',
            newPassword: '',
            newPasswordAgain: '',
        },
    });

    const {
        handleSubmit,
        formState: {isSubmitting},
    } = passwordMethods;

    const onPasswordSubmit = async (data) => {
        UserService.setPassword({
            current_password: data.oldPassword,
            new_password: data.newPassword,
        }, response => {
            passwordMethods.reset();
        }, error => {
            const errorMessage = error.response.data.current_password || 'An error occurred';
            passwordMethods.setError('oldPassword', {
                type: 'manual',
                message: errorMessage,
            });
        })
    }


    return (
        <FormProvider methods={passwordMethods} onSubmit={handleSubmit(onPasswordSubmit)}>
            <Stack spacing={3}>
                <RHFTextField name="oldPassword" label="Current password" type="password"/>
                <RHFTextField name="newPassword" label="New password" type="password"/>
                <RHFTextField name="newPasswordAgain" label="Confirm new password" type="password"/>

                <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
                    Update
                </LoadingButton>
            </Stack>
        </FormProvider>
    )
}