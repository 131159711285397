import {useEffect, useState} from 'react';
// material
import {Button, Card, Container, Stack, Typography} from '@mui/material';
// components
import Page from '../components/Page';
// mock
import AssetsList from "../sections/@dashboard/assets/AssetsList";
import AssetCreateUpdate from "../sections/@dashboard/assets/AssetCreateUpdate";
import AssetService from "../services/AssetService";
import Iconify from "../components/Iconify";

// ----------------------------------------------------------------------

export default function Assets() {

    const [createEditModalOpen, setCreateEditModalOpen] = useState(false);
    const [assets, setAssets] = useState([]);

    useEffect(() => {
        refreshPage();
    }, []);

    const refreshPage = () => {
        getAssets();
    }

    const getAssets = () => {
        AssetService.getAssets(response => {
            setAssets(response.data.assets);
        }, error => {
            console.log(error);
        })
    }

    return (
        <Page title="Assets">
            <Container maxWidth={"xl"}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Assets
                    </Typography>
                    <Button variant="contained"
                            onClick={() => setCreateEditModalOpen(true)}
                            startIcon={<Iconify icon="eva:plus-fill"/>}>
                        New Asset
                    </Button>
                    <AssetCreateUpdate createEditModalOpen={createEditModalOpen}
                                       setCreateEditModalOpen={setCreateEditModalOpen}
                                       refetchAssets={refreshPage}/>
                </Stack>

                <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
                    <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
                        {/*<CategoriesSort />*/}
                    </Stack>
                </Stack>

                <Card>
                    <AssetsList assets={assets} refreshPage={refreshPage}/>
                </Card>
            </Container>
        </Page>
    );
}
