import {useEffect, useState} from 'react';
// material
import {Button, Card, Container, Stack, Typography} from '@mui/material';
// components
import Page from '../components/Page';
import Iconify from "../components/Iconify";
import BudgetsService from "../services/BudgetsService";
import BudgetCreateUpdate from "../sections/@dashboard/budgets/BudgetCreateUpdate";
import BudgetsList from "../sections/@dashboard/budgets/BudgetsList";
import CategoryService from "../services/CategoryService";

// ----------------------------------------------------------------------

export default function Budgets() {

    const [createEditModalOpen, setCreateEditModalOpen] = useState(false);
    const [budgets, setBudgets] = useState([]);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        getCategories();
        refreshPage();
    }, []);

    const refreshPage = () => {
        getBudgets();
    }

    const getBudgets = () => {
        const timeZoneOffset = new Date().getTimezoneOffset();
        BudgetsService.getBudgets(timeZoneOffset, response => {
            setBudgets(response.data.budgets);
        }, error => {
            console.log(error);
        })
    }


    const getCategories = () => {
        CategoryService.getCategories(response => {
            setCategories(response.data.categories.filter(category => !category.date_deleted));
        }, error => {
            console.log(error);
        })
    }

    return (
        <Page title="Budgets">
            <Container maxWidth={"xl"}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Budgets
                    </Typography>
                    <Button variant="contained"
                            onClick={() => setCreateEditModalOpen(true)}
                            startIcon={<Iconify icon="eva:plus-fill"/>}>
                        New Budget
                    </Button>
                    <BudgetCreateUpdate createEditModalOpen={createEditModalOpen}
                                        setCreateEditModalOpen={setCreateEditModalOpen}
                                        categories={categories}
                                        refetchBudgets={refreshPage}/>
                </Stack>

                <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
                    <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
                        {/*<CategoriesSort />*/}
                    </Stack>
                </Stack>

                <Card>
                    <BudgetsList categories={categories} budgets={budgets} refreshPage={refreshPage}/>
                </Card>
            </Container>
        </Page>
    );
}
