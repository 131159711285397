import ApiService from "./ApiService";
import AuthService from "./AuthService";

export default {
    deleteItem(internalItemId, success, failure) {
        ApiService.post('/delete_item', { item_id: internalItemId }, AuthService.getHeaders()).then(success).catch(failure);
    },
    itemReauthenticationSuccessful(plaidItemId, success, failure) {
        ApiService.put('/item_reauthentication_successful', { plaid_item_id: plaidItemId }, AuthService.getHeaders()).then(success).catch(failure);
    }
}